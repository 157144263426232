.logo
  position: absolute
  left: dpx(72)
  top: dpx(39)
  z-index: 5
  @media #{$tablet}
    top: tpx(49)
    left: tpx(24)
  @media #{$mobile}
    top: mpx(19)
    left: mpx(17)
  img
    position: absolute
    left: 0
    top: 0
    display: block
    width: dpx(199)
    height: dpx(53)
    object-fit: contain
    @include noSelect
    @media #{$tablet}
      width: tpx(199)
      height: tpx(53)
    @media #{$mobile}
      width: mpx(118)
      height: mpx(31)
  .logo-white
    opacity: 0
  &--white
    .logo-white
      opacity: 1
      @media #{$tablet}
        opacity: 0
    .logo-grey
      opacity: 0
      @media #{$tablet}
        opacity: 1
