.model
  &-page
    width: 100%
    height: 100%
    position: relative
    padding: dpx(138) dpx(42) dpx(40) dpx(42)
    display: flex
    flex-direction: column
    max-width: dpx(1920)
    margin: 0 auto
    @media #{$tablet}
      padding: tpx(136) tpx(24) 0 tpx(24)
    @media #{$mobile}
      padding: mpx(70) mpx(17) 0 mpx(17)
    .content
      display: flex
      flex-direction: column
      align-items: flex-start
      justify-content: flex-start
      height: 100%
      width: 100%
      position: relative
      @media #{$tablet}
        overflow: hidden auto
        padding-right: tpx(24)
        width: calc(100% + tpx(24))
      @media #{$mobile}
        padding-right: mpx(17)
        padding-bottom: mpx(29)
        width: calc(100% + mpx(17))
      .copyright
        @media #{$tablet}
          position: relative
          padding: tpx(28) 0 tpx(25)
    .copyright
      display: none
      @media #{$tablet}
        display: flex
      @media #{$mobile}
        display: none
      span
        color: $color-06
  &-info
    width: 100%
    height: dpx(494)
    background-color: $color-05
    border-radius: dpx(21)
    padding: dpx(28) dpx(29) dpx(14) dpx(52)
    position: relative
    overflow: auto hidden
    display: flex
    align-items: center
    justify-content: flex-start
    @media #{$tablet}
      height: auto
      margin-bottom: tpx(51)
      border-radius: tpx(21)
      padding: tpx(120) tpx(46) tpx(44) tpx(46)
      flex-direction: column
      overflow: unset
      flex: 1 1 auto
    @media #{$mobile}
      margin-bottom: mpx(43)
      border-radius: mpx(21)
      padding: mpx(96) mpx(17) mpx(31) mpx(17)
    .catalog-item-preview
      margin-right: auto
      height: dpx(362)
      max-width: dpx(842)
      width: calc(100% - dpx(850))
      min-width: dpx(400)
      padding-bottom: dpx(15)
      @media #{$tablet}
        padding-bottom: 0
        max-width: 100%
        width: tpx(842)
        height: tpx(310)
        margin-right: 0
        margin: auto 0
        min-width: unset
      @media #{$mobile}
        width: 100%
        height: mpx(159)
        margin: 0 0 mpx(24) 0
      .swiper
        width: 100%
        height: 100%
        margin-left: 0
        margin-bottom: 0
        padding-left: dpx(41)
        padding-right: dpx(41)
        @media #{$tablet}
          padding-left: tpx(41)
          padding-right: tpx(41)
        @media #{$mobile}
          padding-left: 0
          padding-right: 0
        &:not(.swiper--fullscreen)
          .swiper-slide
            cursor: zoom-in
        &--fullscreen
          position: fixed
          left: 0
          top: 0
          background-color: $white
          z-index: 10
          padding: 0
          .swiper-slide
            .LazyLoad
              width: calc(100% - dpx(60))
              max-width: dpx(1304)
              @media #{$tablet}
                width: 100%
                max-width: tpx(782)
              @media #{$mobile}
                max-width: mpx(258)
          .swiper-navigation-button
            transform: translateY(-50%)
            &--slidePrev
              left: dpx(138)
              @media #{$tablet}
                left: tpx(48)
              @media #{$mobile}
                left: mpx(20)
            &--slideNext
              right: dpx(138)
              transform: translateY(-50%) scale(-1, 1)
              @media #{$tablet}
                right: tpx(48)
              @media #{$mobile}
                right: mpx(20)
        &-close
          position: absolute
          right: dpx(58)
          top: dpx(58)
          cursor: pointer
          border: none
          background: transparent
          z-index: 2
          @media #{$tablet}
            right: tpx(58)
            top: tpx(58)
          @media #{$mobile}
            right: mpx(35)
            top: mpx(35)
          &:hover
            img
              filter: none
          img
            @include noSelect
            aspect-ratio: 1/1
            display: block
            width: dpx(31)
            filter: invert(77%) sepia(23%) saturate(0%) hue-rotate(203deg) brightness(84%) contrast(85%)
            @media #{$tablet}
              width: tpx(31)
            @media #{$mobile}
              width: mpx(31)
        &-slide
          .LazyLoad
            width: 100%
            height: 100%
            max-width: 100%
        &-navigation-button
          left: 0
          width: dpx(58)
          height: dpx(58)
          opacity: 1
          @media #{$tablet}
            display: flex
            width: tpx(60)
            height: tpx(60)
          @media #{$mobile}
            width: mpx(34)
            height: mpx(34)
            left: mpx(5)
          &--slideNext
            left: unset
            right: 0
            @media #{$mobile}
              right: mpx(5)
          img
            width: dpx(24)
            @media #{$tablet}
              width: tpx(22)
            @media #{$mobile}
              width: mpx(11)
    &-text
      height: 100%
      width: dpx(803)
      display: flex
      flex-direction: column
      @media #{$tablet}
        width: 100%
        height: auto
        margin-top: tpx(60)
      @media #{$mobile}
        margin-top: 0
    &-header
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      margin-bottom: dpx(46)
      gap: 0 dpx(16)
      flex: none
      @media #{$tablet}
        gap: 0 tpx(16)
        margin-bottom: auto
        position: absolute
        left: 0
        top: 0
        padding: tpx(36) tpx(29) tpx(36) tpx(42)
      @media #{$mobile}
        gap: 0 mpx(16)
        padding: mpx(19) mpx(17)
        align-items: flex-start
    &-title
      width: 100%
      text-transform: uppercase
      h4
        font-size: dpx(36)
        line-height: dpx(42)
        font-weight: 700
        color: $color-03
        display: -webkit-box
        -webkit-box-orient: vertical
        -webkit-line-clamp: 1
        overflow: hidden
        width: 100%
        @media #{$tablet}
          font-size: tpx(32)
          line-height: tpx(38)
        @media #{$mobile}
          font-size: mpx(21)
          line-height: mpx(25)
          -webkit-line-clamp: 2
      p
        font-size: dpx(23)
        line-height: dpx(27)
        display: -webkit-box
        -webkit-box-orient: vertical
        -webkit-line-clamp: 1
        overflow: hidden
        width: 100%
        @media #{$tablet}
          font-size: tpx(20)
          line-height: tpx(23)
        @media #{$mobile}
          font-size: mpx(13)
          line-height: mpx(15)
    &-buttons
      display: flex
      align-items: center
      justify-content: flex-start
      gap: 0 dpx(16)
      flex: none
      @media #{$tablet}
        gap: 0 tpx(16)
      @media #{$mobile}
        gap: 0 mpx(10)
    &-button
      width: dpx(65)
      height: dpx(65)
      border-radius: dpx(10)
      border: dpx(1) solid $color-01
      background-color: transparent
      display: flex
      align-items: center
      justify-content: center
      cursor: pointer
      @media #{$tablet}
        border-width: tpx(1)
        width: tpx(65)
        height: tpx(65)
        border-radius: tpx(10)
      @media #{$mobile}
        border-width: mpx(1)
        width: mpx(30)
        height: mpx(30)
      &:hover
        background-color: $color-03
        color: $white
        img
          filter: brightness(0) invert(1)
      img
        display: block
        width: dpx(49)
        height: dpx(49)
        @media #{$tablet}
          width: tpx(49)
          height: tpx(49)
        @media #{$mobile}
          width: mpx(22)
          height: mpx(22)
    &-body
      display: grid
      grid-template-columns: dpx(328) 1fr
      gap: 0 dpx(74)
      width: 100%
      @media #{$tablet}
        grid-template-columns: tpx(296) 1fr
        gap: 0 tpx(98)
      @media #{$mobile}
        display: flex
        flex-direction: column-reverse
    &-drawing
      width: 100%
      height: dpx(160)
      flex: none
      @media #{$tablet}
        height: tpx(145)
      @media #{$mobile}
        width: 100%
        height: mpx(90)
        display: flex
        align-items: center
        justify-content: space-between
        gap: 0 mpx(35)
      img
        display: block
        width: 100%
        height: 100%
        object-fit: contain
        @media #{$mobile}
          height: mpx(86)
          width: mpx(177)
      .dimensions
        display: none
        @media #{$mobile}
          display: flex
    &-params
      @media #{$tablet}
        width: 100%
      p
        font-size: dpx(16)
        line-height: dpx(19)
        font-weight: 500
        margin-bottom: dpx(28)
        @media #{$tablet}
          font-size: tpx(16)
          line-height: tpx(19)
          margin-bottom: tpx(28)
        @media #{$mobile}
          font-size: mpx(14)
          line-height: mpx(16)
          margin-bottom: mpx(30)
      .dimensions
        display: flex
        align-items: center
        justify-content: flex-start
        flex-direction: row
        flex-wrap: wrap
        gap: dpx(10) dpx(23)
        @media #{$tablet}
          gap: tpx(10) tpx(23)
        @media #{$mobile}
          display: none
        li
          width: auto
    &-advantages
      margin-top: auto
      list-style-type: none
      width: 100%
      display: grid
      grid-template-columns: repeat(2, 1fr)
      gap: dpx(3) dpx(10)
      @media #{$tablet}
        margin-top: tpx(60)
        grid-template-columns: repeat(3, auto)
      @media #{$mobile}
        margin-top: mpx(30)
        grid-template-columns: repeat(1, 1fr)
        gap: mpx(17) 0
      li
        display: flex
        align-items: flex-start
        justify-content: flex-start
        @media #{$mobile}
          max-width: mpx(240)
        img
          display: block
          width: dpx(20)
          height: dpx(20)
          margin-right: dpx(23)
          @media #{$tablet}
            width: tpx(20)
            height: tpx(20)
            margin-right: tpx(13)
          @media #{$mobile}
            width: mpx(15)
            height: mpx(15)
            margin-right: mpx(12)
        span
          font-size: dpx(16)
          line-height: dpx(19)
          display: -webkit-box
          -webkit-box-orient: vertical
          -webkit-line-clamp: 2
          overflow: hidden
          height: dpx(38)
          @media #{$tablet}
            font-size: tpx(16)
            line-height: tpx(19)
            height: tpx(38)
          @media #{$mobile}
            font-size: mpx(14)
            line-height: mpx(16)
            height: auto
