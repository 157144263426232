@mixin catalogSwiper
  .swiper
    display: block
    width: calc(100% + dpx(44))
    padding: 0 dpx(22)
    margin-left: dpx(-22)
    @media #{$tablet}
      width: calc(100% + tpx(48))
      margin-left: tpx(-24)
      padding: 0 tpx(24)
    @media #{$mobile}
      width: calc(100% + mpx(34))
      padding: 0 mpx(17)
      margin-left: mpx(-17)
    &-slide
      display: flex
      align-items: center
      justify-content: center
      .LazyLoad
        width: 100%
        max-width: dpx(220)
        aspect-ratio: 16/9
        @media #{$tablet}
          max-width: tpx(200)
        @media #{$mobile}
          max-width: mpx(140)
        img
          width: 100%
          height: 100%
          display: block
          object-fit: contain
      video
        width: 100%
        height: 100%
        background-color: $black
        display: block
    &-navigation-button
      position: absolute
      left: dpx(22)
      top: 50%
      transform: translateY(-25%)
      width: dpx(39)
      height: dpx(39)
      display: flex
      align-items: center
      justify-content: center
      background-color: rgba(247, 247, 247, 0.64)
      border-radius: 50%
      border: dpx(1) solid $color-01
      z-index: 1
      cursor: pointer
      opacity: 0
      @media #{$tablet}
        display: none
      &--slideNext
        transform: translateY(-25%) scale(-1, 1)
        left: unset
        right: dpx(22)
      img
        display: block
        width: dpx(17)
        @include noSelect
