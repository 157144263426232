.documents-list
  position: relative
  display: grid
  grid-template-columns: repeat(6, 1fr)
  gap: dpx(50) dpx(29)
  width: 100%
  @media #{$tablet}
    grid-template-columns: repeat(3, 1fr)
    gap: tpx(44)
  @media #{$mobile}
    grid-template-columns: repeat(2, 1fr)
    gap: mpx(18)
  &-item
    position: relative
    border: dpx(1) solid $color-01
    background-color: $color-05
    border-radius: dpx(7)
    padding: dpx(26) dpx(10) 0 dpx(10)
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    height: dpx(122)
    cursor: pointer
    overflow: hidden
    @media #{$tablet}
      border-width: tpx(1)
      border-radius: tpx(7)
      padding: tpx(23) tpx(10) 0 tpx(10)
      height: tpx(123)
    @media #{$mobile}
      border-width: mpx(1)
      border-radius: mpx(7)
      padding: mpx(31) mpx(10) 0 mpx(10)
      height: mpx(130)
    &:hover
      background-color: $color-03
      span
        color: $white
      .documents-list-item__shade
        background-color: transparentize($color-02, 0.25)
      .documents-list-item__icon
        filter: brightness(0) invert(1)
    &__preview,
    &__shade
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      object-fit: cover
      object-position: center
      z-index: 1
    &__shade
      z-index: 2
      background-color: transparentize($color-02, 0.5)
    &__icon
      position: relative
      @include noSelect
      aspect-ratio: 1/1
      width: dpx(28)
      display: block
      margin-bottom: dpx(4)
      z-index: 3
      @media #{$tablet}
        width: tpx(28)
        margin-bottom: tpx(4)
      @media #{$mobile}
        width: mpx(28)
        margin-bottom: mpx(4)
    &__title
      position: relative
      width: 100%
      font-size: dpx(14)
      line-height: dpx(20)
      font-weight: 400
      text-align: center
      display: -webkit-box
      -webkit-box-orient: vertical
      -webkit-line-clamp: 3
      overflow: hidden
      word-break: break-word
      white-space: pre-line
      z-index: 3
      @at-root .documents-list-item--with-preview &
        font-weight: 600
        color: $white
      @media #{$tablet}
        font-size: tpx(14)
        line-height: tpx(20)
      @media #{$mobile}
        font-size: mpx(12)
        line-height: mpx(20)
