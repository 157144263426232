@import './vars'
@import './mixin'
@import './functions'
@import './animation'

*, *:before, *:after
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-decoration: none
  font-family: Raleway
  font-weight: 600
  color: $color-02
  outline: none
  padding: 0
  margin: 0
  box-sizing: border-box
  -webkit-box-sizing: border-box

html, body
  overflow: hidden

.application
  position: fixed
  left: 0
  top: 0
  width: 100vw
  height: calc(100 * var(--vh))
  font-size: dpx(20)
  overflow: hidden
  background-color: $color-08
  .LazyLoad
    position: relative
    &::before
      content: ''
      width: dpx(48)
      height: dpx(48)
      border-radius: 50%
      display: block
      border-top: 3px solid $color-04
      border-right: 3px solid transparent
      animation: rotationTranslate 1s linear infinite
      position: absolute
      left: 50%
      top: 50%
    &.loaded
      &::before
        content: none
      img
        opacity: 1
    img
      opacity: 0
      transition: opacity .3s

@import './components/logo'
@import './components/loader'
@import './components/filters'
@import './components/dimensions'
@import './components/close-group'
@import './components/constructor'
@import './components/catalog-item-preview'
@import './components/catalog-item'
@import './components/catalog-categories'
@import './components/recommendations'
@import './components/animation-modal'
@import './components/pdf-modal'
@import './components/loader-spin'
@import './components/copyright'
@import './components/searchbar'
@import './components/documents-list'

@import './pages/home'
@import './pages/model'
@import './pages/catalog'
@import './pages/login'
@import './pages/admin'
@import './pages/about'
@import './pages/fabrics'
@import './pages/declarations'
@import './pages/instructions'
@import './pages/advertisement'
@import './pages/factory'
@import './pages/salons'
@import './pages/warehouses'
@import './pages/branches-list'
@import './pages/info-page-wrapper'
@import './pages/info-page-tabs'
