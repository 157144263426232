.catalog
  &-pagination
    position: absolute
    right: dpx(20)
    bottom: dpx(-9)
    transform: translateY(100%)
    padding: dpx(7) dpx(15)
    background-color: rgba(218, 218, 218, 0.36)
    backdrop-filter: blur(dpx(4))
    border-radius: dpx(49)
    display: flex
    align-items: center
    justify-content: center
    z-index: 1
    @media #{$tablet}
      right: tpx(12)
      bottom: tpx(-20)
      padding: tpx(7) tpx(15)
      backdrop-filter: blur(tpx(4))
      border-radius: tpx(49)
    @media #{$mobile}
      position: relative
      left: 0
      right: unset
      bottom: unset
      top: 0
      transform: none
      width: 100%
      background-color: #F6F6F6
      padding: 0 mpx(15)
      border-radius: mpx(60)
      margin-top: auto
    &-arrow
      display: flex
      align-items: center
      justify-content: center
      border: none
      background: transparent
      cursor: pointer
      padding: 0 dpx(5)
      @media #{$tablet}
        padding: 0 tpx(5)
      @media #{$mobile}
        padding: 0 mpx(5)
      &:disabled
        cursor: not-allowed
        img
          filter: invert(69%) sepia(0%) saturate(803%) hue-rotate(145deg) brightness(97%) contrast(88%)
      &--prev
        transform: scale(-1, 1)
      img
        @include noSelect
        display: block
        width: dpx(8)
        @media #{$tablet}
          width: tpx(8)
        @media #{$mobile}
          width: mpx(8)
    &-button
      padding: dpx(1) 0 dpx(4) 0
      border: dpx(2) solid $color-01
      background-color: $color-05
      border-radius: dpx(60)
      font-size: dpx(16)
      line-height: dpx(19)
      font-weight: 600
      cursor: pointer
      width: dpx(55)
      @media #{$tablet}
        padding: tpx(1) 0 tpx(4) 0
        border-width: tpx(2)
        border-radius: tpx(60)
        font-size: tpx(16)
        line-height: tpx(19)
        width: tpx(55)
      @media #{$mobile}
        padding: 0
        border-width: 0
        border-radius: 0
        font-size: mpx(16)
        line-height: mpx(19)
        width: auto
        background: transparent !important
        border: none
        position: relative
      &--active
        background-color: $color-04
        @media #{$mobile}
          &::before
            content: ''
            position: absolute
            left: 50%
            bottom: mpx(-3)
            width: mpx(45)
            height: mpx(3)
            background-color: $color-04
            border-radius: mpx(105)
            transform: translate(-50%, 100%)
    .swiper
      --slides-count: 1
      width: calc(dpx(61) * var(--slides-count))
      margin: 0 dpx(18)
      @media #{$tablet}
        width: calc(tpx(61) * var(--slides-count))
        margin: 0 tpx(18)
      @media #{$mobile}
        width: calc(mpx(61) * var(--slides-count))
        margin: 0 auto
        padding: mpx(11) mpx(15) mpx(12) mpx(15)
      &-slide
        padding: 0 dpx(4)
        display: flex
        align-items: center
        @media #{$tablet}
          padding: 0 tpx(4)
        @media #{$mobile}
          padding: 0 mpx(4)
          justify-content: center
  &-page
    width: 100%
    height: 100%
    position: relative
    padding: dpx(33) dpx(22) dpx(80) dpx(42)
    max-width: dpx(1920)
    margin: 0 auto
    @media #{$tablet}
      padding: tpx(33) tpx(12) tpx(100) tpx(24)
    @media #{$mobile}
      padding: mpx(63) 0 0 mpx(17)
    .content
      display: flex
      align-items: flex-start
      justify-content: flex-start
      height: 100%
      position: relative
      @media #{$mobile}
        flex-direction: column
    .loader-spin
      background-color: transparentize($color-08, 0.5)
    .mobile-search
      position: relative
      width: 100%
      height: 100%
      position: fixed
      left: 0
      top: 0
      padding: mpx(60) 0 0 0
      z-index: 2
      pointer-events: none
      display: none
      @media #{$mobile}
        display: block
      &--active
        pointer-events: unset
        .mobile-search-wrapper
          display: block
      &-toggle
        display: flex
        align-items: center
        justify-content: center
        cursor: pointer
        border: mpx(1) solid rgba(166, 166, 166, 0.29)
        border-radius: 50%
        width: mpx(34)
        height: mpx(34)
        background: transparent
        position: absolute
        right: mpx(102)
        top: mpx(18)
        pointer-events: auto
        img
          display: block
          width: mpx(18)
          height: mpx(18)
        &--active
          background: $white
          img
            filter: invert(41%) sepia(3%) saturate(10%) hue-rotate(357deg) brightness(94%) contrast(93%)
      &-close
        position: absolute
        right: mpx(20)
        top: mpx(10)
        width: mpx(20)
        height: mpx(20)
        border-radius: 50%
        border: mpx(1) solid rgba(166, 166, 166, 0.29)
        display: flex
        align-items: center
        justify-content: center
        background: transparent
        img
          @include noSelect
          display: block
          width: mpx(12)
          filter: invert(43%) sepia(8%) saturate(0%) hue-rotate(175deg) brightness(86%) contrast(88%)
      &-submit
        border: 1px solid $color-04
        padding: mpx(5) mpx(14)
        border-radius: mpx(50)
        background: transparent
        font-size: mpx(12)
        line-height: mpx(14)
        margin-top: mpx(30)
      &-wrapper
        padding: 0 mpx(16) mpx(19) mpx(16)
        width: 100%
        height: 100%
        background-color: $color-08
        display: none
      &-content
        position: relative
        width: 100%
        height: 100%
        background-color: $white
        border-radius: mpx(15)
        border: mpx(1) solid #D3D3D3
        padding: mpx(44) mpx(20)
        display: flex
        flex-direction: column
        align-items: center
        justify-content: flex-start
        .searchbar
          width: 100%
          input
            width: 100%
            padding: 0 mpx(25) 0 mpx(20)
            height: mpx(33)
            &::placeholder
              color: $color-06
          button
            left: unset !important
            right: mpx(10) !important
            img
              filter: invert(66%) sepia(0%) saturate(3%) hue-rotate(347deg) brightness(98%) contrast(101%)
  &-mobile-close
    display: none
    @media #{$mobile}
      display: flex
      align-items: center
      justify-content: center
      cursor: pointer
      border: mpx(1) solid rgba(166, 166, 166, 0.29)
      border-radius: 50%
      width: mpx(34)
      height: mpx(34)
      background: transparent
      position: absolute
      right: mpx(17)
      top: mpx(18)
      img
        display: block
        width: mpx(18)
        height: mpx(18)
  &-mobile-overflow
    width: calc(100% - dpx(328) - dpx(20))
    height: 100%
    display: flex
    flex-direction: column
    position: relative
    @media #{$tablet}
      width: calc(100% - tpx(231) - tpx(18))
    @media #{$mobile}
      width: 100%
      padding-right: mpx(17)
      padding-bottom: mpx(19)
      overflow: hidden auto
      position: relative
      z-index: 1
    .loader-spin
      position: absolute
      top: 0
      left: 0
      height: 100%
      z-index: 1
      @media #{$mobile}
        position: fixed
        z-index: 2
  &-list
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    align-content: flex-start
    justify-content: flex-start
    gap: dpx(20) dpx(37)
    width: 100%
    height: 100%
    padding: 0 dpx(20) 0 0
    position: relative
    overflow: hidden auto
    z-index: 1
    @media #{$tablet}
      gap: tpx(31) tpx(18)
      padding: 0 tpx(12) 0 0
    @media #{$mobile}
      width: 100%
      height: auto
      gap: mpx(14) mpx(18)
      padding: 0 0 mpx(18) 0
      position: unset
      overflow: unset
      align-items: stretch
    &::-webkit-scrollbar
      @include scrollbar
    &::-webkit-scrollbar-thumb
      @include scrollbarThumb
    &::-webkit-scrollbar-track
      @include scrollbarTrack
    &::-webkit-scrollbar-button
      @include scrollbarButton
    &-empty
      display: flex
      flex-direction: column
      align-items: center
      justify-content: center
      width: 100%
      padding-top: dpx(56)
      @media #{$tablet}
        padding-top: tpx(56)
      @media #{$mobile}
        padding-top: mpx(100)
      &-icon
        width: dpx(45)
        height: dpx(45)
        display: flex
        align-items: center
        justify-content: center
        border-radius: 50%
        background-color: $color-05
        margin-bottom: dpx(19)
        @media #{$tablet}
          width: tpx(45)
          height: tpx(45)
          margin-bottom: tpx(19)
        @media #{$mobile}
          width: mpx(45)
          height: mpx(45)
          margin-bottom: mpx(19)
        img
          aspect-ratio: 1/1
          display: block
          width: dpx(24)
          @media #{$tablet}
            width: tpx(24)
          @media #{$mobile}
            width: mpx(24)
      p
        font-size: dpx(16)
        line-height: dpx(19)
        font-weight: 600
        text-align: center
        @media #{$tablet}
          font-size: tpx(16)
          line-height: tpx(19)
        @media #{$mobile}
          font-size: mpx(16)
          line-height: mpx(19)
          max-width: mpx(237)
    &-load-more
      width: calc((100% - dpx(38 * 4)) / 5)
      margin: dpx(15) auto
      border-radius: dpx(105)
      padding: dpx(15) 0
      background-color: $color-04
      font-size: dpx(16)
      line-height: dpx(19)
      border: none
      cursor: pointer
      @media #{$tablet}
        width: calc((100% - tpx(18 * 2)) / 3)
        margin: 0 auto tpx(10) auto
        border-radius: tpx(105)
        padding: tpx(15)
        font-size: tpx(16)
        line-height: tpx(19)
      @media #{$mobile}
        width: calc(50% - mpx(9))
        margin: 0 auto mpx(10) auto
        padding: mpx(12)
        font-size: mpx(15)
        line-height: mpx(18)
      &-wrapper
        width: 100%
        display: flex
