@font-face {
	font-family: 'Raleway';
	src: url('Raleway-Thin.eot');
	src: local('Raleway Thin'), local('Raleway-Thin'),
		url('Raleway-Thin.eot?#iefix') format('embedded-opentype'),
		url('Raleway-Thin.woff') format('woff'),
		url('Raleway-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('Raleway-ExtraLight.eot');
	src: local('Raleway ExtraLight'), local('Raleway-ExtraLight'),
		url('Raleway-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('Raleway-ExtraLight.woff') format('woff'),
		url('Raleway-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('Raleway-Light.eot');
	src: local('Raleway Light'), local('Raleway-Light'),
		url('Raleway-Light.eot?#iefix') format('embedded-opentype'),
		url('Raleway-Light.woff') format('woff'),
		url('Raleway-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('Raleway-Regular.eot');
	src: local('Raleway'), local('Raleway-Regular'),
		url('Raleway-Regular.eot?#iefix') format('embedded-opentype'),
		url('Raleway-Regular.woff') format('woff'),
		url('Raleway-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('Raleway-Medium.eot');
	src: local('Raleway Medium'), local('Raleway-Medium'),
		url('Raleway-Medium.eot?#iefix') format('embedded-opentype'),
		url('Raleway-Medium.woff') format('woff'),
		url('Raleway-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('Raleway-SemiBold.eot');
	src: local('Raleway SemiBold'), local('Raleway-SemiBold'),
		url('Raleway-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('Raleway-SemiBold.woff') format('woff'),
		url('Raleway-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('Raleway-Bold.eot');
	src: local('Raleway Bold'), local('Raleway-Bold'),
		url('Raleway-Bold.eot?#iefix') format('embedded-opentype'),
		url('Raleway-Bold.woff') format('woff'),
		url('Raleway-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('Raleway-ExtraBold.eot');
	src: local('Raleway ExtraBold'), local('Raleway-ExtraBold'),
		url('Raleway-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('Raleway-ExtraBold.woff') format('woff'),
		url('Raleway-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('Raleway-Black.eot');
	src: local('Raleway Black'), local('Raleway-Black'),
		url('Raleway-Black.eot?#iefix') format('embedded-opentype'),
		url('Raleway-Black.woff') format('woff'),
		url('Raleway-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
