.info-page-wrapper
  width: 100%
  height: 100%
  position: relative
  padding: dpx(127) dpx(42) dpx(42) dpx(42)
  @media #{$tablet}
    padding: tpx(127) tpx(23) tpx(33) tpx(23)
  @media #{$mobile}
    padding: mpx(66) mpx(16) mpx(19) mpx(16)
  &-navigation
    position: absolute
    right: dpx(149)
    top: dpx(33)
    display: flex
    align-items: center
    justify-content: center
    padding: dpx(18)
    border-radius: dpx(49)
    background-color: rgba(218, 218, 218, 0.36)
    gap: dpx(17)
    @media #{$tablet}
      right: tpx(118)
      top: tpx(33)
      padding: tpx(18)
      border-radius: tpx(49)
      gap: tpx(17)
    @media #{$mobile}
      right: mpx(59)
      top: mpx(19)
      padding: 0
      border-radius: 0
      gap: mpx(9)
      background-color: transparent
    a
      display: flex
      align-items: center
      justify-content: center
      padding: dpx(10) dpx(21)
      border-radius: dpx(105)
      background-color: #F6F6F6
      @media #{$tablet}
        padding: tpx(10) tpx(21)
        border-radius: tpx(105)
      @media #{$mobile}
        padding: mpx(9)
        border-radius: 50%
        background-color: transparent
        border: 1px solid rgba(166, 166, 166, 0.29)
      &.active
        background-color: $color-04 !important
        border-color: $color-04
      img
        @include noSelect
        aspect-ratio: 1/1
        width: dpx(25)
        margin-right: dpx(10)
        @media #{$tablet}
          width: tpx(25)
          margin-right: tpx(10)
        @media #{$mobile}
          width: mpx(16)
          margin-right: 0
      span
        font-size: dpx(16)
        line-height: dpx(19)
        @media #{$tablet}
          font-size: tpx(16)
          line-height: tpx(19)
        @media #{$mobile}
          display: none
  &-close
    position: absolute
    right: dpx(42)
    top: dpx(33)
    padding: dpx(18)
    border-radius: 50%
    background-color: rgba(218, 218, 218, 0.36)
    display: flex
    align-items: center
    justify-content: center
    @media #{$tablet}
      right: tpx(23)
      top: tpx(33)
      padding: tpx(18)
    @media #{$mobile}
      right: mpx(15)
      top: mpx(19)
      padding: 0
      border: 1px solid rgba(166, 166, 166, 0.29)
      background-color: transparent
    &-icon
      width: dpx(45)
      height: dpx(45)
      background-color: $color-05
      border-radius: 50%
      display: flex
      align-items: center
      justify-content: center
      @media #{$tablet}
        width: tpx(45)
        height: tpx(45)
      @media #{$mobile}
        width: mpx(35)
        height: mpx(35)
        background-color: transparent
      img
        @include noSelect
        display: block
        aspect-ratio: 1/1
        width: dpx(24)
        @media #{$tablet}
          width: tpx(24)
        @media #{$mobile}
          width: mpx(16)
