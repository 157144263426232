.home
  &-page
    width: 100%
    height: 100%
    position: relative
    background-color: $white
    .navigation
      position: absolute
      right: dpx(72)
      top: dpx(40)
      display: flex
      align-items: center
      justify-content: flex-start
      z-index: 2
      @media #{$tablet}
        right: tpx(43)
        top: tpx(40)
      @media #{$mobile}
        right: mpx(20)
        top: mpx(16)
      &-link
        margin-left: dpx(20)
        display: flex
        align-items: center
        justify-content: center
        padding: dpx(10) dpx(15)
        font-size: dpx(16)
        line-height: dpx(19)
        font-weight: 600
        background-color: $color-07
        border-radius: dpx(105)
        @media #{$tablet}
          margin-left: tpx(20)
          padding: tpx(10) tpx(15)
          font-size: tpx(16)
          line-height: tpx(19)
          border-radius: tpx(105)
        @media #{$mobile}
          margin-left: mpx(9)
          padding: mpx(6)
          border-radius: 50%
        span
          @media #{$mobile}
            display: none
        img
          aspect-ratio: 1/1
          width: dpx(24)
          margin-right: dpx(10)
          @media #{$tablet}
            width: tpx(24)
            margin-right: tpx(10)
          @media #{$mobile}
            width: mpx(12)
            margin-right: 0
    .background
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      &-slide
        position: relative
        img
          display: block
          width: 100%
          height: 100%
          object-fit: cover
          object-position: left top
          position: relative
          z-index: 1
          @media #{$tablet}
            object-position: center bottom
        &-text
          position: absolute
          left: dpx(68)
          top: dpx(130)
          width: dpx(737)
          display: block
          z-index: 2
          @media #{$tablet}
            top: unset
            left: 50%
            bottom: tpx(593)
            transform: translateX(-50%)
            width: tpx(590)
            text-align: center
          @media #{$mobile}
            bottom: mpx(360)
            width: calc(100% - mpx(44))
            height: calc(percent(109, 635) * 1%)
          h1
            font-size: dpx(79)
            line-height: dpx(93)
            color: $color-03
            text-transform: uppercase
            @media #{$tablet}
              font-size: tpx(62)
              line-height: tpx(73)
            @media #{$mobile}
              font-size: mpx(24)
              line-height: mpx(20)
          h2
            font-size: dpx(57)
            line-height: dpx(67)
            margin-bottom: dpx(18)
            text-transform: uppercase
            @media #{$tablet}
              font-size: tpx(45)
              line-height: tpx(52)
              margin-bottom: tpx(18)
            @media #{$mobile}
              font-size: mpx(17)
              line-height: mpx(20)
              margin-bottom: mpx(6)
          p
            font-size: dpx(20)
            line-height: dpx(23)
            font-weight: 500
            @media #{$tablet}
              font-size: tpx(16)
              line-height: tpx(19)
            @media #{$mobile}
              font-size: mpx(12)
              line-height: mpx(14)
      .swiper-pagination
        position: absolute
        left: dpx(294)
        top: dpx(402)
        display: flex
        align-items: center
        justify-content: center
        z-index: 3
        @media #{$tablet}
          left: tpx(250)
          top: unset
          bottom: tpx(539)
        @media #{$mobile}
          display: none
        &-bullet
          width: dpx(14)
          height: dpx(14)
          border-radius: 50%
          background-color: $color-05
          cursor: pointer
          margin-right: dpx(8)
          @media #{$tablet}
            width: tpx(8)
            height: tpx(8)
            margin-right: tpx(20)
          &-active
            background-color: $color-02
    .content
      display: flex
      flex-direction: column
      align-items: flex-start
      justify-content: flex-end
      height: 100%
      width: 100%
      position: absolute
      left: 0
      top: 0
      padding: dpx(40) dpx(42)
      z-index: 2
      @media #{$tablet}
        padding: tpx(69) tpx(24)
      @media #{$mobile}
        padding: mpx(29) mpx(17)
      .recommendations
        margin-top: dpx(50)
        @media #{$tablet}
          margin-top: tpx(53)
        @media #{$mobile}
          margin-top: mpx(33)
        &-list
          background-color: rgba(255, 255, 255, 0.2)
          backdrop-filter: blur(10px)
          @media #{$tablet}
            backdrop-filter: none
          @media #{$mobile}
            background-color: transparent
        &-pad
          backdrop-filter: blur(10px)
          @media #{$tablet}
            backdrop-filter: none
    .categories
      display: flex
      align-items: flex-end
      justify-content: flex-start
      position: relative
      max-width: 100%
      @media #{$mobile}
        width: 100%
        padding: mpx(12)
        border-radius: mpx(14)
        background-color: rgba(255, 255, 255, 0.2)
        &::before
          content: ''
          position: absolute
          left: 50%
          top: 50%
          transform: translate(-50%, -50%)
          width: mpx(1)
          height: mpx(36)
          background-color: $color-05
      &-pad
        position: absolute
        left: 0
        top: 0
        width: dpx(234)
        transform: translateY(-100%)
        pointer-events: none
        backdrop-filter: blur(10px)
        border-radius: dpx(21) dpx(21) 0 0
        z-index: 1
        @include noSelect
        @media #{$tablet}
          width: tpx(208)
          border-radius: tpx(19) tpx(19) 0 0
          top: tpx(1)
        @media #{$mobile}
          display: none
      &-link
        width: dpx(144)
        height: dpx(144)
        border-radius: dpx(10)
        border: dpx(1) solid #DEDEDE
        background-color: $color-05
        display: flex
        align-items: center
        justify-content: flex-start
        flex-direction: column
        flex: none
        cursor: pointer
        padding: dpx(23) dpx(25)
        @media #{$tablet}
          border-width: tpx(1)
          width: tpx(133)
          height: tpx(133)
          border-radius: tpx(9)
          padding: tpx(22) tpx(25)
        @media #{$mobile}
          border-width: mpx(1)
          width: mpx(124)
          height: mpx(124)
          border-radius: mpx(7)
          padding: mpx(27) mpx(17)
        &:hover
          background-color: $color-03
          img
            filter: brightness(0) invert(1)
          span
            color: $white
        img
          display: block
          width: dpx(44)
          height: dpx(44)
          margin-bottom: dpx(8)
          @media #{$tablet}
            width: tpx(40)
            height: tpx(40)
            margin-bottom: tpx(7)
          @media #{$mobile}
            width: mpx(28)
            height: mpx(28)
            margin-bottom: mpx(5)
        span
          font-size: dpx(16)
          line-height: dpx(19)
          text-align: center
          @media #{$tablet}
            font-size: tpx(16)
            line-height: tpx(19)
          @media #{$mobile}
            font-size: mpx(12)
            line-height: mpx(15)
        &-wrapper,
        &-list
          padding: dpx(28)
          background-color: rgba(255, 255, 255, 0.2)
          backdrop-filter: blur(10px)
          border-radius: dpx(21)
          border: none
          @media #{$tablet}
            backdrop-filter: none
            padding: tpx(22)
            border-radius: tpx(19)
          @media #{$mobile}
            padding: 0
            border-radius: 0
            background: transparent
        &-wrapper
          @media #{$mobile}
            border-top-left-radius: 0
            border-bottom-left-radius: 0
            width: 50%
            display: flex
            justify-content: flex-end
        &-list
          margin-right: dpx(27)
          border-top-left-radius: 0
          position: relative
          z-index: 2
          width: dpx(1196)
          max-width: calc(100% - dpx(227))
          overflow: hidden
          padding-right: 0
          @media #{$tablet}
            padding-right: tpx(48)
            margin-right: tpx(13)
            width: tpx(792)
            max-width: calc(100% - tpx(188))
          @media #{$mobile}
            padding-right: 0
            margin-right: 0
            width: 50%
            max-width: 50%
            border-top-right-radius: 0
            border-bottom-right-radius: 0
          &--show-arrow
            width: dpx(1256)
            padding-right: dpx(60)
            @media #{$tablet}
              padding-right: tpx(48)
            .swiper
              padding-right: 0 !important
            .swiper-button-next
              display: block !important
          .swiper
            width: 100%
            padding-right: dpx(28)
            @media #{$tablet}
              padding-right: 0
            @media #{$mobile}
              touch-action: none
            &-slide
              width: auto
              margin-right: dpx(22)
              @media #{$tablet}
                margin-right: tpx(13)
              @media #{$mobile}
                margin-right: mpx(15)
                display: none
              &:nth-last-child(1)
                margin-right: 0
              &:nth-child(1)
                display: block
            &-button-next
              position: absolute
              right: dpx(9)
              top: 50%
              transform: translate(0, -50%)
              cursor: pointer
              border: none
              background: transparent
              display: none
              @media #{$tablet}
                right: tpx(9)
                display: block
              @media #{$mobile}
                display: none
              img
                display: block
                width: dpx(44)
                height: dpx(44)
                @media #{$tablet}
                  width: tpx(33)
                  height: tpx(33)
          &-tabs
            position: absolute
            left: dpx(28)
            top: dpx(-14)
            display: flex
            align-items: center
            justify-content: flex-start
            z-index: 3
            @media #{$tablet}
              left: tpx(22)
              top: tpx(-14)
            @media #{$mobile}
              display: none
          &-tab
            display: flex
            align-items: center
            justify-content: flex-start
            background-color: transparent
            border: none
            position: relative
            img
              display: block
              width: dpx(24)
              height: dpx(24)
              margin-right: dpx(10)
              @media #{$tablet}
                width: tpx(20)
                height: tpx(29)
                margin-right: tpx(9)
            span
              font-size: dpx(22)
              line-height: dpx(26)
              font-weight: 400
              @media #{$tablet}
                font-size: tpx(20)
                line-height: tpx(24)
