.copyright
  position: absolute
  left: 0
  bottom: 0
  width: 100%
  padding: 0 dpx(42) dpx(12) dpx(42)
  z-index: 5
  display: flex
  align-items: center
  justify-content: space-between
  @media #{$tablet}
    padding: 0 tpx(42) tpx(14) tpx(42)
    flex-direction: column
  @media #{$mobile}
    padding: 0 0 mpx(7) 0
  span
    font-size: dpx(15)
    line-height: dpx(17)
    font-weight: 400
    color: $white
    @media #{$tablet}
      font-size: tpx(15)
      line-height: tpx(17)
      text-align: center
      &:nth-child(1)
        margin-bottom: tpx(8)
    @media #{$mobile}
      font-size: mpx(8)
      line-height: mpx(9)
      color: $color-02
      &:nth-child(1)
        margin-bottom: 0
