.info-page-tabs
  position: relative
  width: 100%
  height: 100%
  padding-top: dpx(47)
  @media #{$tablet}
    padding-top: tpx(47)
  @media #{$mobile}
    padding-top: 0
  &-buttons
    position: absolute
    left: dpx(30)
    top: dpx(-15)
    display: flex
    align-items: center
    justify-content: flex-start
    gap: dpx(44)
    @media #{$tablet}
      left: tpx(47)
      top: tpx(-15)
      gap: tpx(44)
    @media #{$mobile}
      left: 0
      top: 0
      gap: mpx(22)
      position: relative
      padding: mpx(15) mpx(17)
      overflow: auto
    &::before
      content: ''
      position: absolute
      left: dpx(-30)
      top: dpx(-16)
      width: calc(100% + dpx(60))
      height: dpx(276)
      border-radius: dpx(21)
      background-color: $color-05
      z-index: -1
      @media #{$tablet}
        left: tpx(-47)
        top: tpx(-16)
        width: calc(100% + tpx(94))
        height: tpx(395)
        border-radius: tpx(21)
      @media #{$mobile}
        display: none
    button
      background: transparent
      border: none
      font-weight: 400
      font-size: dpx(22)
      line-height: dpx(26)
      position: relative
      z-index: 1
      color: #A6A6A6
      cursor: pointer
      @media #{$tablet}
        font-size: tpx(22)
        line-height: tpx(26)
      @media #{$mobile}
        font-size: mpx(16)
        line-height: mpx(19)
        white-space: nowrap
      &.active
        color: $color-02
        &::before
          content: ''
          position: absolute
          left: 0
          bottom: dpx(-4)
          width: 100%
          height: dpx(3)
          border-radius: dpx(105)
          background-color: $color-04
          transform: translateY(100%)
          @media #{$tablet}
            bottom: tpx(-4)
            height: tpx(3)
            border-radius: tpx(105)
          @media #{$mobile}
            bottom: mpx(-3)
            height: mpx(3)
            border-radius: mpx(105)
  &-content
    width: 100%
    height: 100%
    background-color: $color-05
    border-radius: dpx(21)
    position: relative
    @media #{$tablet}
      border-radius: tpx(21)
    @media #{$mobile}
      border-radius: mpx(21)
