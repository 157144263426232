.searchbar
  display: flex
  align-items: center
  justify-content: center
  position: relative
  button
    border: none
    background-color: $color-07
    position: absolute
    left: dpx(15)
    top: 50%
    transform: translateY(-50%)
    pointer-events: none
    transition: left .3s, transform .3s
    cursor: pointer
    @media #{$tablet}
      left: tpx(15)
    @media #{$mobile}
      left: 6px
    &:hover
      img
        filter: none
    img
      aspect-ratio: 1/1
      width: dpx(24)
      display: block
      @media #{$tablet}
        width: tpx(24)
      @media #{$mobile}
        width: mpx(12)
  input
    border: none
    background-color: $color-07
    border-radius: dpx(105)
    width: dpx(124)
    height: dpx(44)
    padding: 0 dpx(26) 0 dpx(49)
    font-size: dpx(16)
    line-height: dpx(19)
    font-weight: 600
    transition: width .3s, padding .3s
    @media #{$tablet}
      border-radius: tpx(105)
      width: tpx(124)
      height: tpx(44)
      padding: 0 tpx(26) 0 tpx(49)
      font-size: tpx(16)
      line-height: tpx(19)
    @media #{$mobile}
      border-radius: mpx(58)
      width: mpx(24)
      height: mpx(24)
      padding: 0
      font-size: mpx(10)
      line-height: mpx(12)
    &::placeholder
      color: $color-02
      @media #{$mobile}
        color: transparentize($color-02, 1)
  &--active
    button
      left: dpx(340)
      pointer-events: unset
      @media #{$tablet}
        left: tpx(194)
      @media #{$mobile}
        left: mpx(108)
      img
        filter: invert(66%) sepia(0%) saturate(3%) hue-rotate(347deg) brightness(98%) contrast(101%)
    input
      padding: 0 dpx(40) 0 dpx(20)
      width: dpx(376)
      @media #{$tablet}
        padding: 0 tpx(40) 0 tpx(20)
        width: tpx(231)
      @media #{$mobile}
        padding: 0 mpx(20) 0 mpx(10)
        width: mpx(126)
      &::placeholder
        color: $color-06