.loader-spin
  position: absolute
  width: 100%
  height: 100%
  left: 0
  top: 0
  z-index: 2
  display: flex
  align-items: center
  justify-content: center
  background-color: transparentize($white, 0.5)
  &-circle
    width: dpx(48)
    height: dpx(48)
    border-radius: 50%
    display: block
    border-top: 3px solid $color-04
    border-right: 3px solid transparent
    animation: rotation 1s linear infinite
