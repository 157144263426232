.filters
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  flex: none
  width: dpx(328)
  height: 100%
  overflow: hidden auto
  position: relative
  padding-top: dpx(104)
  @media #{$tablet}
    width: tpx(231)
    padding-top: tpx(112)
  @media #{$mobile}
    background-color: #F8F8F8
    padding: mpx(22) mpx(24) 0 mpx(24)
    width: mpx(279)
    height: 100%
    border-radius: mpx(14)
    border: mpx(1) solid #D3D3D3
    display: none
  &::-webkit-scrollbar
    @include scrollbar
  &::-webkit-scrollbar-thumb
    @include scrollbarThumb
  &::-webkit-scrollbar-track
    @include scrollbarTrack
  &::-webkit-scrollbar-button
    @include scrollbarButton
  &-header
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: dpx(29)
    width: 100%
    @media #{$tablet}
      margin-bottom: tpx(24)
    @media #{$mobile}
      margin-bottom: mpx(24)
    p
      font-size: dpx(16)
      line-height: dpx(19)
      @media #{$tablet}
        font-size: tpx(16)
        line-height: tpx(19)
      @media #{$mobile}
        font-size: mpx(16)
        line-height: mpx(19)
    button
      background: transparent
      border: none
      cursor: pointer
      font-size: dpx(16)
      line-height: dpx(19)
      color: #6F6F6F
      text-decoration: underline
      @media #{$tablet}
        font-size: tpx(16)
        line-height: tpx(19)
      @media #{$mobile}
        font-size: mpx(16)
        line-height: mpx(19)
  &-wrapper
    height: 100%
    margin-right: dpx(20)
    @media #{$tablet}
      margin-right: tpx(18)
    @media #{$mobile}
      margin-right: 0
      width: 100%
      position: fixed
      left: 0
      top: 0
      padding: mpx(53) mpx(17)
      z-index: 2
      pointer-events: none
      &--active
        pointer-events: unset
        .filters
          display: flex
    &-button
      display: none
      @media #{$mobile}
        display: flex
        align-items: center
        justify-content: center
        cursor: pointer
        border: mpx(1) solid rgba(166, 166, 166, 0.29)
        border-radius: 50%
        width: mpx(34)
        height: mpx(34)
        background: transparent
        position: absolute
        right: mpx(59)
        top: mpx(18)
        pointer-events: auto
        img
          display: block
          width: mpx(18)
          height: mpx(18)
        &--active
          background: $white
          img
            filter: invert(41%) sepia(3%) saturate(10%) hue-rotate(357deg) brightness(94%) contrast(93%)
  h4
    margin-bottom: dpx(20)
    font-size: dpx(16)
    line-height: dpx(19)
    color: #939393
    @media #{$tablet}
      margin-bottom: tpx(20)
      font-size: tpx(14)
      line-height: tpx(16)
    @media #{$mobile}
      margin-bottom: mpx(20)
      font-size: mpx(14)
      line-height: mpx(16)
  hr
    width: 100%
    margin: dpx(20) 0
    border-top: dpx(1) solid $color-01
    border-bottom: none
    @media #{$tablet}
      border-width: tpx(1)
      margin: tpx(20) 0
    @media #{$mobile}
      border-width: mpx(1)
      margin: mpx(20) 0
  &-options-group
    display: grid
    grid-template-columns: repeat(2, 1fr)
    width: 100%
    gap: dpx(8) dpx(7.5)
    @media #{$tablet}
      grid-template-columns: repeat(1, 1fr)
      gap: tpx(10)
    @media #{$mobile}
      gap: mpx(8)
    &--full
      grid-template-columns: repeat(1, 1fr)
  &-select
    position: relative
    width: 100%
    height: dpx(43)
    border: dpx(1) solid $color-01
    background-color: $color-05
    border-radius: dpx(13)
    display: flex
    align-items: center
    justify-content: flex-start
    cursor: pointer
    flex: none
    @media #{$tablet}
      border-width: tpx(1)
      height: tpx(39)
      border-radius: tpx(12)
    @media #{$mobile}
      border-width: mpx(1)
      height: mpx(39)
      border-radius: mpx(12)
    select
      width: 100%
      height: 100%
      border-radius: unherit
      background: transparent
      border: none
      cursor: pointer
      appearance: none
      padding: 0 dpx(25) 0 dpx(45)
      font-size: dpx(16)
      line-height: dpx(19)
      @media #{$tablet}
        padding: 0 tpx(17) 0 tpx(40)
        font-size: tpx(14)
        line-height: tpx(16)
      @media #{$mobile}
        padding: 0 mpx(22) 0 mpx(40)
        font-size: mpx(14)
        line-height: mpx(16)
    &-icon
      display: block
      position: absolute
      left: 0
      top: 50%
      transform: translateY(-50%)
      pointer-events: none
      width: dpx(24)
      height: dpx(24)
      object-fit: contain
      @media #{$tablet}
        width: tpx(21)
        height: tpx(21)
      @media #{$mobile}
        width: mpx(21)
        height: mpx(21)
      &--left
        left: dpx(10)
        @media #{$tablet}
          left: tpx(9)
        @media #{$mobile}
          left: mpx(9)
      &--end
        left: unset
        right: dpx(10)
        @media #{$tablet}
          right: tpx(9)
        @media #{$mobile}
          right: mpx(9)
  &-option
    display: flex
    align-items: center
    justify-content: flex-start
    cursor: pointer
    &:hover
      .filters-option-box
        &::before
          opacity: 0.15
    &-box
      width: dpx(31)
      height: dpx(31)
      border: dpx(1) solid #C4C4C4
      border-radius: dpx(10)
      margin-right: dpx(8)
      position: relative
      flex: none
      @media #{$tablet}
        border-width: tpx(1)
        width: tpx(28)
        height: tpx(28)
        border-radius: tpx(9)
        margin-right: tpx(7)
      @media #{$mobile}
        border-width: mpx(1)
        width: mpx(28)
        height: mpx(28)
        border-radius: mpx(9)
        margin-right: mpx(7)
      &::before
        content: ''
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
        background-position: center
        background-size: cover
        background-repeat: no-repeat
        background-image: url(/assets/img/icon--check.svg)
        opacity: 0
    input
      display: none
      &:checked + .filters-option-box
        border-color: $color-03
        background-color: $color-05
        &:before
          opacity: 1
    p
      font-size: dpx(16)
      line-height: dpx(19)
      display: -webkit-box
      -webkit-box-orient: vertical
      -webkit-line-clamp: 2
      overflow: hidden
      @media #{$tablet}
        font-size: tpx(14)
        line-height: tpx(16)
      @media #{$mobile}
        font-size: mpx(14)
        line-height: mpx(16)
      span
        width: 100%
        font-size: dpx(12)
        line-height: dpx(14)
        display: -webkit-box
        -webkit-box-orient: vertical
        -webkit-line-clamp: 2
        overflow: hidden
        @media #{$tablet}
          font-size: tpx(11)
          line-height: tpx(13)
        @media #{$mobile}
          font-size: mpx(11)
          line-height: mpx(13)
  &-submit-button
    display: none
    @media #{$mobile}
      display: flex
      align-items: center
      justify-content: center
      flex: none
      position: sticky
      left: 0
      bottom: 0
      width: 100%
      height: mpx(80)
      background-color: inherit
      margin-top: mpx(5)
      button
        width: mpx(136)
        height: mpx(43)
        border-radius: mpx(101)
        background-color: $color-04
        border: none
        font-size: mpx(15)
        line-height: mpx(18)
        display: flex
        align-items: center
        justify-content: center
