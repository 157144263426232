.dimensions
  list-style-type: none
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  gap: dpx(6) 0
  width: 100%
  @media #{$tablet}
    gap: tpx(5) 0
  @media #{$mobile}
    gap: mpx(4) 0
  li
    --label: 'L'
    font-size: dpx(16)
    line-height: dpx(19)
    position: relative
    width: 100%
    display: flex
    align-items: center
    justify-content: flex-start
    @media #{$tablet}
      font-size: tpx(15)
      line-height: tpx(17)
    @media #{$mobile}
      font-size: mpx(12)
      line-height: mpx(14)
    &::before
      position: relative
      content: var(--label)
      left: 0
      top: 0
      width: dpx(31)
      height: dpx(31)
      display: block
      display: flex
      align-items: center
      justify-content: center
      border: dpx(1) solid $color-01
      background-color: $color-05
      margin-right: dpx(8)
      border-radius: dpx(10)
      font-weight: 400
      @media #{$tablet}
        border-width: tpx(1)
        width: tpx(28)
        height: tpx(28)
        margin-right: tpx(7)
        border-radius: tpx(9)
      @media #{$mobile}
        border-width: mpx(1)
        width: mpx(21)
        height: mpx(21)
        margin-right: mpx(5)
        border-radius: mpx(7)