.pdf-modal
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  background-color: $color-05
  z-index: 20
  &-content
    width: 100%
    height: 100%
    overflow: hidden
    position: relative
    padding: 0 dpx(15)
    @media #{$tablet}
      padding: 0 tpx(15)
    @media #{$mobile}
      padding: mpx(80) mpx(8) 0 mpx(8)
    .react-pdf__Document
      width: 100%
      height: 100%
      overflow: hidden auto
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-start
      padding: dpx(50) dpx(15)
      gap: dpx(50)
      @media #{$tablet}
        padding: tpx(40) tpx(15)
        gap: tpx(40)
      @media #{$mobile}
        padding: 0 mpx(7) mpx(30) mpx(7)
        gap: mpx(30)
      *
        font-weight: normal
      &::-webkit-scrollbar
        @include scrollbar
        background: transparent
      &::-webkit-scrollbar-thumb
        @include scrollbarThumb
      &::-webkit-scrollbar-track
        margin: dpx(100) 0 dpx(46) 0
        @include scrollbar
        @media #{$tablet}
          margin: tpx(100) 0 tpx(44) 0
        @media #{$mobile}
          margin: mpx(18) 0
      &::-webkit-scrollbar-button
        @include scrollbarButton
    .react-pdf__Page
      min-width: unset !important
      min-height: unset !important
      max-width: 100%
      box-shadow: dpx(2) dpx(5) dpx(10) rgba(0, 0, 0, 0.3)
      @media #{$tablet}
        box-shadow: tpx(2) tpx(5) tpx(10) rgba(0, 0, 0, 0.3)
      @media #{$mobile}
        box-shadow: mpx(2) mpx(5) mpx(10) rgba(0, 0, 0, 0.3)
      &__canvas
        max-width: 100%
        height: auto !important
      &__annotations
        width: 100% !important
        height: 100% !important
  &-close,
  &-download
    position: absolute
    right: dpx(58)
    top: dpx(58)
    cursor: pointer
    border: none
    background: transparent
    z-index: 2
    @media #{$tablet}
      right: tpx(58)
      top: tpx(58)
    @media #{$mobile}
      right: mpx(15)
      top: mpx(25)
    &:hover
      img
        filter: none
    img
      @include noSelect
      aspect-ratio: 1/1
      display: block
      width: dpx(31)
      filter: invert(77%) sepia(23%) saturate(0%) hue-rotate(203deg) brightness(84%) contrast(85%)
      @media #{$tablet}
        width: tpx(31)
      @media #{$mobile}
        width: mpx(31)
  &-download
    right: dpx(116)
    top: dpx(53)
    @media #{$tablet}
      right: tpx(116)
      top: tpx(53)
    @media #{$mobile}
      right: mpx(60)
      top: mpx(20)
    img
      width: dpx(41)
      @media #{$tablet}
        width: tpx(41)
      @media #{$mobile}
        width: mpx(41)
