.instructions-page
  width: 100%
  height: 100%
  position: relative
  padding: dpx(65) dpx(15) 0 dpx(30)
  @media #{$tablet}
    padding: tpx(58) tpx(25) 0 tpx(50)
  @media #{$mobile}
    padding: mpx(11) mpx(8) 0 mpx(17)
    height: calc(100% - mpx(49))
  &-overflow
    width: 100%
    height: 100%
    overflow: auto
    padding: 0 dpx(15) dpx(52) 0
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    @media #{$tablet}
      padding: 0 tpx(25) tpx(44) 0
    @media #{$mobile}
      padding: 0 mpx(9) mpx(18) 0
    &::-webkit-scrollbar
      @include scrollbar
      background: transparent
    &::-webkit-scrollbar-thumb
      @include scrollbarThumb
    &::-webkit-scrollbar-track
      margin: 0 0 dpx(46) 0
      @include scrollbar
      @media #{$tablet}
        margin: 0 0 tpx(44) 0
      @media #{$mobile}
        margin: 0 0 mpx(18) 0
    &::-webkit-scrollbar-button
      @include scrollbarButton
