.fabrics-page
  width: 100%
  height: 100%
  position: relative
  padding: dpx(65) dpx(15) 0 dpx(30)
  @media #{$tablet}
    padding: tpx(58) tpx(25) 0 tpx(50)
  @media #{$mobile}
    padding: mpx(11) mpx(8) 0 mpx(17)
    height: calc(100% - mpx(49))
  h1
    font-size: dpx(24)
    line-height: dpx(28)
    margin: dpx(70) 0 dpx(23) 0
    @media #{$tablet}
      font-size: tpx(24)
      line-height: tpx(28)
      margin: tpx(70) 0 tpx(23) 0
    @media #{$mobile}
      font-size: mpx(21)
      line-height: mpx(25)
      margin: mpx(40) 0 mpx(20) 0
    &:nth-child(1)
      margin-top: 0 !important
  &-overflow
    width: 100%
    height: 100%
    overflow: auto
    padding: 0 dpx(15) dpx(52) 0
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    @media #{$tablet}
      padding: 0 tpx(25) tpx(44) 0
    @media #{$mobile}
      padding: 0 mpx(9) mpx(18) 0
    &::-webkit-scrollbar
      @include scrollbar
      background: transparent
    &::-webkit-scrollbar-thumb
      @include scrollbarThumb
    &::-webkit-scrollbar-track
      margin: 0 0 dpx(46) 0
      @include scrollbar
      @media #{$tablet}
        margin: 0 0 tpx(44) 0
      @media #{$mobile}
        margin: 0 0 mpx(18) 0
    &::-webkit-scrollbar-button
      @include scrollbarButton
  .suppliers-list
    position: relative
    display: grid
    grid-template-columns: repeat(6, 1fr)
    gap: dpx(50) dpx(29)
    width: 100%
    @media #{$tablet}
      grid-template-columns: repeat(3, 1fr)
      gap: tpx(44)
    @media #{$mobile}
      grid-template-columns: repeat(2, 1fr)
      gap: mpx(18)
    &-item
      border: dpx(1) solid $color-01
      background-color: $color-05
      border-radius: dpx(7)
      padding: dpx(23) dpx(10) 0 dpx(10)
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-start
      height: dpx(122)
      @media #{$tablet}
        border-width: tpx(1)
        border-radius: tpx(7)
        padding: tpx(23) tpx(10) 0 tpx(10)
        height: tpx(123)
      @media #{$mobile}
        border-width: mpx(1)
        border-radius: mpx(7)
        padding: mpx(31) mpx(10) 0 mpx(10)
        height: mpx(130)
      img
        @include noSelect
        height: dpx(31)
        width: 100%
        max-width: dpx(164)
        object-fit: contain
        display: block
        margin-bottom: dpx(6)
        @media #{$tablet}
          height: tpx(32)
          max-width: tpx(164)
          margin-bottom: tpx(6)
        @media #{$mobile}
          height: mpx(21)
          max-width: mpx(114)
          margin-bottom: mpx(6)
      span
        font-size: dpx(16)
        line-height: dpx(19)
        text-align: center
        text-transform: uppercase
        display: -webkit-box
        -webkit-box-orient: vertical
        -webkit-line-clamp: 2
        overflow: hidden
        margin-bottom: dpx(6)
        @media #{$tablet}
          font-size: tpx(16)
          line-height: tpx(19)
          margin-bottom: tpx(6)
        @media #{$mobile}
          font-size: mpx(16)
          line-height: mpx(19)
          margin-bottom: mpx(6)
      a
        font-weight: 400
        font-size: dpx(14)
        line-height: dpx(16)
        text-align: center
        color: #1C299F
        @media #{$tablet}
          font-size: tpx(14)
          line-height: tpx(16)
        @media #{$mobile}
          font-size: mpx(14)
          line-height: mpx(16)
