.admin
  &-page
    width: 100%
    height: 100%
    position: relative
    padding: 0 dpx(42) dpx(33) dpx(42)
    max-width: dpx(1920)
    margin: 0 auto
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    overflow: auto
    &-header
      display: flex
      align-items: center
      justify-content: space-between
      position: sticky
      top: 0
      background-color: #E8E8E8
      padding: dpx(33) 0
      &-user
        display: flex
        align-items: center
        justify-content: flex-start
        p
          text-transform: capitalize
        button
          margin-left: 15px
          cursor: pointer
    &-models
      display: grid
      grid-template-columns: repeat(5, 1fr)
      gap: 20px
      &-item
        background-color: $white
        border-radius: 10px
        padding: 20px
        display: flex
        flex-direction: column
        align-items: flex-start
        justify-content: flex-start
        img
          width: 100%
          height: 75px
          object-fit: contain
          margin-bottom: 15px
        span
          margin-bottom: auto
        button
          margin-top: 15px
          cursor: pointer
    &-modal
      display: flex
      width: 100%
      height: 100%
      position: fixed
      left: 0
      top: 0
      background-color: transparentize($white, 0.5)
      backdrop-filter: blur(3px)
      overflow: auto
      &-content
        width: 100%
        max-width: 500px
        margin: auto
        display: flex
        flex-direction: column
        align-items: center
        justify-content: flex-start
        gap: 20px
        padding: 50px 30px 60px 30px
        background-color: $white
        border-radius: 15px
        h2
          margin-bottom: 10px
        button
          cursor: pointer
      &-submit
        width: 100%
        max-width: 300px
        height: 43px
        padding: 0 20px
        border-radius: 101px
        border: none
        background-color: $color-04
