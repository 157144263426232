.recommendations
  margin-top: auto
  position: relative
  width: 100%
  @media #{$mobile}
    width: calc(100% + mpx(17))
  &-pad
    position: absolute
    left: 0
    top: 0
    width: dpx(431)
    transform: translateY(-100%)
    pointer-events: none
    border-radius: dpx(21) dpx(21) 0 0
    @include noSelect
    @media #{$tablet}
      width: tpx(389)
      border-radius: tpx(19) tpx(19) 0 0
    @media #{$mobile}
      top: unset
      bottom: 0
      width: 100%
      border-radius: 0
      transform: none
  &-tabs
    position: absolute
    left: dpx(28)
    top: dpx(-14)
    display: grid
    grid-template-columns: repeat(2, auto)
    gap: 0 dpx(52)
    z-index: 2
    @media #{$tablet}
      left: tpx(22)
      top: tpx(-14)
      gap: 0 tpx(48)
    @media #{$mobile}
      left: mpx(12)
      top: mpx(-16)
      gap: 0 mpx(26)
  &-tab
    display: flex
    align-items: center
    justify-content: flex-start
    background-color: transparent
    border: none
    cursor: pointer
    position: relative
    &--active
      &:before
        content: ''
        position: absolute
        left: 0
        bottom: dpx(-5)
        width: 100%
        height: dpx(3)
        border-radius: dpx(105)
        background-color: $color-04
        transform: translateY(100%)
        @media #{$tablet}
          bottom: tpx(-5)
          border-radius: tpx(97)
        @media #{$mobile}
          bottom: mpx(-3)
          border-radius: mpx(74)
    img
      display: block
      width: dpx(24)
      height: dpx(24)
      margin-right: dpx(10)
      @media #{$tablet}
        width: tpx(20)
        height: tpx(20)
        margin-right: tpx(9)
      @media #{$mobile}
        width: mpx(15)
        height: mpx(15)
        margin-right: mpx(6)
    span
      font-size: dpx(22)
      line-height: dpx(26)
      font-weight: 400
      @media #{$tablet}
        font-size: tpx(20)
        line-height: tpx(24)
      @media #{$mobile}
        font-size: mpx(14)
        line-height: mpx(16)
  &-list
    width: 100%
    height: dpx(247)
    border-radius: 0 dpx(21) dpx(21) dpx(21)
    background-color: $color-05
    padding: dpx(40) dpx(28) dpx(28) dpx(28)
    position: relative
    z-index: 1
    @media #{$tablet}
      height: tpx(228)
      border-radius: 0 tpx(19) tpx(19) tpx(19)
      padding: tpx(37) tpx(48) tpx(22) tpx(22)
    @media #{$mobile}
      height: mpx(152)
      border-radius: 0 mpx(14) mpx(14) mpx(14)
      padding: mpx(16) 0 mpx(12) mpx(12)
      background-color: transparent
    .swiper
      width: 100%
      height: 100%
      &-button-next
        position: absolute
        right: tpx(9)
        top: 50%
        transform: translate(0, -50%)
        cursor: pointer
        border: none
        background: transparent
        display: none
        @media #{$tablet}
          display: block
        @media #{$mobile}
          display: none
        img
          display: block
          width: tpx(33)
          height: tpx(33)
    .swiper-slide
      margin-right: calc(percent(83, 1780) * 1%)
      width: calc(percent(380, 1780) * 1%)
      @media #{$tablet}
        margin-right: calc(percent(18, 906) * 1%)
        width: calc(percent(290, 906) * 1%)
      @media #{$mobile}
        width: mpx(220)
        margin-right: mpx(17)
      &:nth-last-child(1)
        margin-right: 0
      @media #{$desktop}
        @media (max-aspect-ratio: 15/9)
          @media (min-aspect-ratio: 1/1)
            width: calc((100% - dpx(50 * 1)) / 2)
            margin-right: dpx(50)
          @media (min-aspect-ratio: 6/5)
            width: calc((100% - dpx(50 * 2)) / 3)
            margin-right: dpx(50)
  &-item
    display: flex
    justify-content: flex-start
    width: 100%
    &-preview
      width: calc(percent(254, 380) * 1%)
      height: dpx(179)
      margin-right: dpx(19)
      border: dpx(1) solid $color-01
      background-color: $color-05
      display: flex
      align-items: center
      justify-content: center
      border-radius: dpx(10)
      flex: none
      @media #{$tablet}
        border-width: tpx(1)
        width: calc(percent(171, 290) * 1%)
        height: tpx(165)
        margin-right: tpx(11)
        border-radius: tpx(9)
      @media #{$mobile}
        border-width: mpx(1)
        width: mpx(124)
        height: mpx(124)
        margin-right: mpx(13)
        border-radius: mpx(7)
      img
        display: block
        width: calc(percent(169, 254) * 1%)
        @media #{$tablet}
          width: calc(percent(149, 171) * 1%)
        @media #{$mobile}
          width: calc(percent(112, 124) * 1%)
    &-text
      display: flex
      flex-direction: column
      align-items: flex-start
      justify-content: flex-start
      h4
        font-size: dpx(16)
        line-height: dpx(19)
        font-weight: 700
        margin-bottom: dpx(3)
        display: -webkit-box
        -webkit-box-orient: vertical
        -webkit-line-clamp: 2
        overflow: hidden
        width: 100%
        text-transform: uppercase
        @media #{$tablet}
          font-size: tpx(15)
          line-height: tpx(17)
          margin-bottom: tpx(3)
        @media #{$mobile}
          font-size: mpx(12)
          line-height: mpx(14)
          margin-bottom: mpx(3)
      p
        font-size: dpx(14)
        line-height: dpx(16)
        margin-bottom: auto
        text-transform: uppercase
        @media #{$tablet}
          font-size: tpx(13)
          line-height: tpx(15)
        @media #{$mobile}
          font-size: mpx(12)
          line-height: mpx(14)
