@mixin noSelect
  user-select: none
  -webkit-user-select: none
  user-drag: none
  -webkit-user-drag: none

@mixin noTouch
  touch-action: none
  -webkit-touch-action: none

@mixin highResolution
  -webkit-backface-visibility: hidden
  -ms-transform: translateZ(0)
  -webkit-transform: translateZ(0)
  transform: translateZ(0)

@mixin scrollbar
  width: dpx(5)
  background: transparentize($color-06, 0.2)
  border-radius: dpx(10)
  @media #{$tablet}
    width: tpx(8)
    border-radius: tpx(10)
  @media #{$mobile}
    width: mpx(5)
    border-radius: mpx(10)

@mixin scrollbarThumb
  background: $color-02
  border-radius: dpx(10)
  cursor: pointer
  @media #{$tablet}
    border-radius: tpx(10)
  @media #{$mobile}
    border-radius: mpx(10)

@mixin scrollbarTrack
  display: none

@mixin scrollbarButton
  display: none
