.loader
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  background-color: $white
  z-index: 100
  &--transparent
    background-color: transparentize($white, 0.7)
    p
      display: none
  p
    font-size: dpx(24)
    margin-bottom: dpx(15)
    @media #{$tablet}
      font-size: tpx(24)
      margin-bottom: tpx(15)
    @media #{$mobile}
      font-size: mpx(18)
      margin-bottom: tpx(10)
  &-circle
    width: dpx(50)
    height: dpx(50)
    border-radius: 50%
    display: block
    border-top: dpx(3) solid $color-04
    border-right: dpx(3) solid transparent
    animation: rotation 1s linear infinite
    @media #{$tablet}
      width: tpx(50)
      height: tpx(50)
      border-top: tpx(3) solid $color-04
      border-right: tpx(3) solid transparent
    @media #{$mobile}
      width: mpx(40)
      height: mpx(40)
      border-top: mpx(3) solid $color-04
      border-right: mpx(3) solid transparent
  progress
    width: dpx(270)
    height: dpx(12)
    appearance: none
    -webkit-appearance: none
    @media #{$tablet}
      width: tpx(270)
      height: tpx(12)
    @media #{$mobile}
      width: mpx(220)
      height: mpx(10)
    &::-webkit-progress-value
      background-color: $color-04 !important
      border-radius: inherit
    &::-moz-progress-bar
      background-color: $color-04 !important
      border-radius: inherit
    &::-webkit-progress-bar
      background-color: $color-08
      border-radius: dpx(10)
