$mobile: "only screen and (max-width: 600px) and (orientation: portrait)"
$mobileLandscape: "only screen and (max-width: 900px) and (orientation: landscape)"
$tablet: "only screen and (max-width: 1200px) and (orientation: portrait)"
$tabletLandscape: "only screen and (max-width: 1400px) and (orientation: landscape)"
$desktop: "only screen and (min-width: 1440px)"

$white: #FFFFFF
$black: #000000

$color-01: #DEDEDE
$color-02: #232323
$color-03: #781C3A
$color-04: #EEAB00
$color-05: #F7F7F7
$color-06: #A6A6A6
$color-07: #F5F5F5
$color-08: #E8E8E8
