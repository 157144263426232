.factory-page
  position: relative
  padding: dpx(51) dpx(30) dpx(27) dpx(30)
  display: flex
  flex-direction: column
  height: 100%
  @media #{$tablet}
    padding: tpx(51) tpx(46) tpx(46) tpx(46)
  @media #{$mobile}
    padding: mpx(13) mpx(17) 0 mpx(17)
    height: calc(100% - mpx(49))
    overflow: hidden auto
  h1
    font-weight: 700
    font-size: dpx(36)
    line-height: dpx(42)
    margin-bottom: dpx(26)
    color: $color-03
    @media #{$tablet}
      font-size: tpx(32)
      line-height: tpx(38)
      margin-bottom: tpx(20)
    @media #{$mobile}
      font-size: mpx(21)
      line-height: mpx(25)
      margin-bottom: mpx(20)
      max-width: mpx(200)
  &-row
    display: flex
    align-items: flex-start
    justify-content: flex-start
    height: calc(100% - dpx(68))
    @media #{$tablet}
      height: calc(100% - tpx(58))
      flex-direction: column
    @media #{$mobile}
      height: auto
  &-preview
    width: calc(100% - dpx(653))
    max-width: 52.13%
    height: 100%
    margin-right: dpx(67)
    background-color: $color-08
    border-radius: dpx(10)
    min-width: dpx(500)
    @media #{$tablet}
      width: 100%
      height: tpx(493)
      max-width: unset
      margin-right: 0
      border-radius: tpx(10)
      margin-bottom: tpx(20)
      flex: none
      min-width: unset
    @media #{$mobile}
      height: mpx(203)
      border-radius: mpx(8)
      margin-bottom: mpx(20)
    img
      width: 100%
      height: 100%
      display: block
      object-fit: cover
      object-position: center
      border-radius: inherit
  &-contacts
    height: 100%
    width: dpx(586)
    overflow: hidden auto
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    align-content: flex-start
    @media #{$tablet}
      width: 100%
      flex-direction: row
      flex-wrap: wrap
    @media #{$mobile}
      flex-direction: column
      flex-wrap: nowrap
      padding-bottom: mpx(18)
      height: auto
    h2
      font-size: dpx(24)
      line-height: dpx(28)
      font-weight: 700
      @media #{$tablet}
        font-size: tpx(24)
        line-height: tpx(28)
        width: 100%
      @media #{$mobile}
        font-size: mpx(18)
        line-height: mpx(21)
  &-contact
    margin-top: dpx(16)
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: dpx(7)
    @media #{$tablet}
      margin-top: tpx(30)
      gap: tpx(7)
      width: 50%
    @media #{$mobile}
      margin-top: mpx(24)
      gap: mpx(7)
      width: 100%
    &:nth-child(2)
      padding-bottom: dpx(8)
      @media #{$tablet}
        padding-bottom: tpx(10)
        width: 100%
      @media #{$mobile}
        padding-bottom: 0
        margin-top: mpx(15)
      p
        display: none
    &-name
      font-size: dpx(20)
      line-height: dpx(23)
      margin-bottom: dpx(3)
      @media #{$tablet}
        font-size: tpx(20)
        line-height: tpx(23)
        margin-bottom: tpx(3)
      @media #{$mobile}
        font-size: mpx(18)
        line-height: mpx(21)
        margin-bottom: mpx(3)
    &-block
      display: flex
      align-items: flex-start
      justify-content: flex-start
      width: 100%
      &-values
        width: 100%
        display: flex
        flex-direction: column
        align-items: flex-start
        justify-content: flex-start
        gap: dpx(5)
        @media #{$tablet}
          gap: tpx(5)
        @media #{$mobile}
          gap: mpx(5)
      img
        @include noSelect
        display: block
        width: dpx(25)
        margin-right: dpx(14)
        @media #{$tablet}
          width: tpx(25)
          margin-right: tpx(14)
        @media #{$mobile}
          width: mpx(20)
          margin-right: mpx(14)
      span, a
        font-weight: 500
        font-size: dpx(18)
        line-height: dpx(21)
        @media #{$tablet}
          font-size: tpx(18)
          line-height: tpx(21)
        @media #{$mobile}
          font-size: mpx(14)
          line-height: mpx(19)
      span
        display: block
