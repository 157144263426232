.constructor
  position: fixed
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  background-color: $white
  width: 100%
  height: 100%
  z-index: 10
  touch-action: none
  overflow: hidden
  display: flex
  align-items: center
  justify-content: center
  &-name
    position: absolute
    left: 50%
    top: dpx(56)
    transform: translateX(-50%)
    @media (min-aspect-ratio: 1/1)
      @media (max-aspect-ratio: 1440/969)
        left: 37.5%
      @media (max-aspect-ratio: 1200/969)
        left: 50%
        top: dpx(140)
    @media #{$tablet}
      top: tpx(167)
    &-background
      position: absolute
      left: 50%
      top: 50%
      transform: translate(-50%, -50%)
      width: calc(100% + dpx(36))
      height: calc(100% + dpx(20))
      z-index: 1
      background: rgba(255, 255, 255, 0.35)
      filter: blur(dpx(14.5))
      border-radius: dpx(21)
      @media #{$tablet}
        width: calc(100% + tpx(36))
        height: calc(100% + tpx(20))
        filter: blur(tpx(14.5))
        border-radius: tpx(21)
    p
      font-size: dpx(28)
      line-height: dpx(32)
      font-weight: 500
      text-transform: uppercase
      position: relative
      z-index: 2
      @media #{$tablet}
        font-size: tpx(28)
        line-height: tpx(32)
  &-wrapper
    width: 100%
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden
  &--showroom
    .constructor-wrapper
      @media #{$tablet}
        width: 100vw
        height: calc(100vw / (1024/744))
    .constructor-material-picker,
    .constructor-decor-color-picker,
    .constructor-animation-button,
    .constructor-ar-button
      display: none !important
    .constructor-preset-picker
      display: flex
  &-mobile-message
    display: none
    @media #{$mobile}
      position: absolute
      left: 0
      top: 0
      width: 100%
      height: 100%
      background-color: $white
      z-index: 4
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column
      text-align: center
      img
        display: block
        width: mpx(57)
        height: mpx(57)
      p
        font-size: mpx(16)
        line-height: mpx(19)
        margin-top: mpx(13)
    @media #{$mobileLandscape}
      display: none
  &-preset-picker
    display: none
    position: absolute
    left: 0
    bottom: 0
    width: 100%
    padding: dpx(40)
    align-items: flex-end
    justify-content: space-between
    @media #{$tablet}
      padding: tpx(70) tpx(23)
    &-pad
      display: block
      position: absolute
      left: 0
      top: dpx(2)
      transform: translateY(-100%)
      z-index: 1
      @include noSelect
  &-wall-picker,
  &-floor-picker,
  &-curtain-picker
    height: dpx(171)
    padding: dpx(18)
    background-color: $color-05
    border-radius: 0 dpx(21) dpx(21) dpx(21)
    display: grid
    grid-template-columns: repeat(3, auto)
    gap: 0 dpx(19)
    position: relative
    @media #{$tablet}
      height: tpx(126)
      padding: tpx(15)
      border-radius: 0 tpx(21) tpx(21) tpx(21)
      gap: 0 tpx(14)
    p
      position: absolute
      left: dpx(18)
      top: dpx(-20)
      font-size: dpx(16)
      line-height: dpx(19)
      color: $color-06
      z-index: 2
      @media #{$tablet}
        top: tpx(-19)
        font-size: tpx(16)
        line-height: tpx(19)
    button
      width: dpx(120)
      height: dpx(135)
      border-radius: dpx(10)
      border: dpx(1) solid $color-01
      background-color: #ECECEC
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-start
      padding: dpx(14) 0 dpx(15) 0
      cursor: pointer
      @media #{$tablet}
        border-width: tpx(1)
        width: tpx(86)
        height: tpx(95)
        border-radius: tpx(10)
        padding: tpx(10) 0
      img
        display: block
        width: dpx(82)
        height: dpx(82)
        border-radius: 50%
        background-color: $color-05
        object-fit: cover
        @media #{$tablet}
          width: tpx(58)
          height: tpx(58)
      span
        margin-top: auto
        font-size: dpx(16)
        line-height: dpx(19)
        @media #{$tablet}
          font-size: tpx(12)
          line-height: tpx(14)
  &-wall-picker
    p
      left: dpx(21)
      @media #{$tablet}
        left: tpx(17)
    .constructor-preset-picker-pad
      width: dpx(105)
      @media #{$tablet}
        width: tpx(94)
    button
      &.active
        background-color: $color-04
  &-floor-picker
    p
      left: dpx(33)
      @media #{$tablet}
        left: tpx(27)
    .constructor-preset-picker-pad
      width: dpx(105)
      @media #{$tablet}
        width: tpx(94)
    button
      &.active
        background-color: $color-04
  &-curtain-picker
    display: grid
    grid-template-columns: repeat(5, 1fr)
    gap: dpx(8) dpx(16)
    @media #{$tablet}
      gap: tpx(6) tpx(13)
    p
      left: dpx(41)
      @media #{$tablet}
        left: tpx(22)
    .constructor-preset-picker-pad
      width: dpx(178)
      top: dpx(4)
      @media #{$tablet}
        width: tpx(130)
    button
      padding: 0
      width: dpx(57)
      height: dpx(57)
      border: none
      border-radius: 50%
      border: dpx(1) solid $color-05
      @media #{$tablet}
        width: tpx(45)
        height: tpx(45)
      &.active
        border-color: $color-04
      img
        width: 100%
        height: 100%
  &-material-picker
    position: absolute
    left: dpx(42)
    bottom: dpx(40)
    padding: dpx(14) dpx(19)
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    background-image: url(/assets/img/material-picker.svg)
    background-size: 100% 100%
    background-repeat: no-repeat
    background-position: top left
    @media #{$tablet}
      left: tpx(24)
      bottom: tpx(69)
      padding: tpx(14) tpx(16)
    &-layers
      display: flex
      align-items: center
      justify-content: flex-start
      margin-bottom: dpx(22)
      @media #{$tablet}
        margin-bottom: tpx(19)
      p
        font-size: dpx(16)
        line-height: dpx(19)
        margin-right: dpx(54)
        color: $color-06
        @media #{$tablet}
          font-size: tpx(14)
          line-height: tpx(16)
          margin-right: tpx(46)
      button
        position: relative
        background: transparent
        border: none
        cursor: pointer
        margin-left: dpx(27)
        font-size: dpx(16)
        line-height: dpx(19)
        color: $color-06
        @media #{$tablet}
          margin-left: tpx(22)
          font-size: tpx(14)
          line-height: tpx(16)
        &:nth-child(2)
          margin-left: 0
        &:disabled
          cursor: not-allowed
          opacity: 0.25
        &.active
          color: $color-02
          &:before
            content: ''
            position: absolute
            left: 0
            bottom: dpx(-2)
            width: 100%
            height: dpx(3)
            border-radius: dpx(105)
            background-color: $color-04
            transform: translateY(100%)
            @media #{$tablet}
              bottom: tpx(-2)
              height: tpx(2)
              border-radius: tpx(91)
    &-materials
      display: flex
      align-items: center
      justify-content: flex-start
      label
        margin-right: dpx(21)
        border: dpx(1) solid $color-05
        width: dpx(144)
        height: dpx(142)
        border-radius: dpx(19)
        background-color: #EBEBEB
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        cursor: pointer
        position: relative
        @media #{$tablet}
          border-width: tpx(1)
          margin-right: tpx(18)
          width: tpx(124)
          height: tpx(122)
          border-radius: tpx(8)
        &:nth-last-child(1)
          margin-right: 0
        &.active
          background-color: $color-04
          border-color: $color-01
        img
          display: block
          border-radius: 50%
          width: dpx(82)
          height: dpx(82)
          margin-bottom: dpx(8)
          object-fit: cover
          object-position: center
          @include noSelect
          @media #{$tablet}
            width: tpx(70)
            height: tpx(70)
            margin-bottom: tpx(7)
        span
          font-size: dpx(16)
          line-height: dpx(19)
          text-align: center
          @media #{$tablet}
            font-size: tpx(14)
            line-height: tpx(19)
        input
          position: absolute
          left: 0
          top: 0
          opacity: 0
          pointer-events: none
  &-decor-color-picker
    --slides: 1
    position: absolute
    right: dpx(42)
    bottom: dpx(40)
    border: dpx(1) solid $color-01
    border-radius: dpx(21)
    padding: dpx(13) 0
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    background-color: #F7F7F7
    max-width: calc(100% - dpx(842) - dpx(84) - dpx(63))
    min-width: dpx(186)
    width: calc((dpx(144) * var(--slides)) + (dpx(21) * (var(--slides) - 1)) + dpx(40))
    @media #{$tablet}
      border-width: tpx(1)
      right: tpx(24)
      bottom: tpx(69)
      border-radius: tpx(18)
      padding: tpx(13) 0 tpx(11) 0
      width: tpx(166)
      min-width: unset
      max-width: unset
    p
      font-size: dpx(16)
      line-height: dpx(19)
      margin-bottom: dpx(22)
      color: $color-06
      width: 100%
      padding: 0 dpx(20)
      @media #{$tablet}
        font-size: tpx(14)
        line-height: tpx(16)
        margin-bottom: tpx(19)
        text-align: center
        padding: 0 tpx(20)
    .swiper
      width: 100%
      padding: 0 dpx(20)
      @media #{$tablet}
        padding: 0 tpx(20)
      &-navigation-button
        position: absolute
        left: tpx(5)
        top: 50%
        transform: translateY(-50%)
        width: tpx(34)
        height: tpx(34)
        display: none
        align-items: center
        justify-content: center
        background-color: rgba(247, 247, 247, 0.64)
        border-radius: 50%
        border: tpx(1) solid $color-01
        z-index: 1
        cursor: pointer
        @media #{$tablet}
          display: flex
        &--slideNext
          transform: translateY(-50%) scale(-1, 1)
          left: unset
          right: tpx(5)
        img
          display: block
          width: tpx(15)
          @include noSelect
    .swiper-slide
      position: relative
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-start
      padding: dpx(16) 0 dpx(17) 0
      border-radius: dpx(10)
      background-color: #EBEBEB
      width: dpx(144)
      height: dpx(142)
      cursor: pointer
      margin-right: dpx(21)
      @media #{$tablet}
        width: tpx(124)
        height: tpx(124)
        padding: tpx(13) 0 tpx(15) 0
        border-radius: tpx(9)
        margin-right: tpx(22)
      &:nth-last-child(1)
        margin-right: 0
      &.active
        background-color: $color-04
        border-color: $color-01
      span
        font-size: dpx(16)
        line-height: dpx(19)
        @media #{$tablet}
          font-size: tpx(14)
          line-height: tpx(16)
      input
        position: absolute
        left: 0
        top: 0
        opacity: 0
        pointer-events: none
    &-list
      display: flex
      align-items: center
      justify-content: flex-start
    &--color
      width: dpx(82)
      height: dpx(82)
      margin-bottom: dpx(8)
      border-radius: 50%
      object-fit: cover
      object-position: center
      display: block
      @include noSelect
      @media #{$tablet}
        width: tpx(70)
        height: tpx(70)
        margin-bottom: tpx(7)
  &-environment-switch
    position: absolute
    right: dpx(206)
    top: dpx(33)
    display: grid
    grid-template-columns: repeat(2, auto)
    background-color: rgba(218, 218, 218, 0.36)
    border: dpx(1) solid $color-01
    border-radius: dpx(49)
    padding: dpx(18)
    gap: dpx(21)
    backdrop-filter: blur(4.5px)
    @media #{$tablet}
      border-width: tpx(1)
      right: tpx(189)
      top: tpx(35)
      border-radius: tpx(49)
      padding: tpx(17)
      gap: tpx(21)
    button
      border: none
      cursor: pointer
      background-color: $color-05
      border-radius: dpx(105)
      display: flex
      align-items: center
      justify-content: center
      padding: dpx(11) dpx(15) dpx(10) dpx(15)
      @media #{$tablet}
        border-radius: tpx(105)
        padding: tpx(11) tpx(15) tpx(10) tpx(15)
      &:disabled
        background-color: $color-04
      img
        display: block
        width: dpx(24)
        height: dpx(24)
        margin-right: dpx(11)
        object-fit: contain
        @include noSelect
        @media #{$tablet}
          width: tpx(24)
          height: tpx(24)
          margin-right: tpx(11)
      span
        font-size: dpx(16)
        line-height: dpx(19)
        @media #{$tablet}
          font-size: tpx(16)
          line-height: tpx(19)
  &-animation-button,
  &-ar-button
    position: absolute
    right: dpx(42)
    bottom: dpx(278)
    display: flex
    align-items: center
    justify-content: center
    background-color: #F7F7F7
    border: dpx(1) solid $color-01
    border-radius: dpx(103)
    padding: dpx(8) dpx(10)
    cursor: pointer
    backdrop-filter: blur(4.5px)
    @media #{$tablet}
      border-width: tpx(1)
      right: tpx(24)
      bottom: tpx(275)
      border-radius: tpx(88)
      padding: tpx(8) tpx(10)
    img
      display: block
      margin-right: dpx(15)
      width: dpx(78)
      height: dpx(78)
      @include noSelect
      @media #{$tablet}
        margin-right: tpx(13)
        width: tpx(67)
        height: tpx(67)
    span
      font-size: dpx(22)
      line-height: dpx(26)
      font-weight: 400
      color: $color-02
      width: dpx(129)
      text-align: left
      @media #{$tablet}
        font-size: tpx(18)
        line-height: tpx(21)
        width: tpx(112)
  &-ar-button
    right: unset !important
    left: dpx(42)
    @media #{$tablet}
      left: tpx(24)
