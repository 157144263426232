.catalog-item
  width: calc((100% - dpx(38 * 4)) / 5)
  height: dpx(365)
  background-color: $color-05
  border-radius: dpx(21)
  padding: dpx(47) dpx(22) dpx(28) dpx(22)
  position: relative
  cursor: pointer
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start
  @media (min-aspect-ratio: 1/1)
    @media (max-aspect-ratio: 1550/969)
      width: calc((100% - dpx(38 * 3)) / 4)
    @media (max-aspect-ratio: 1300/969)
      width: calc((100% - dpx(38 * 2)) / 3)
    @media (max-aspect-ratio: 1100/969)
      width: calc((100% - dpx(38)) / 2)
  @media #{$tablet}
    width: calc((100% - tpx(18 * 2)) / 3)
    height: tpx(355)
    border-radius: tpx(17)
    padding: tpx(39) tpx(24) tpx(24) tpx(24)
  @media #{$mobile}
    width: calc(50% - mpx(9))
    height: auto
    border-radius: mpx(12)
    padding: mpx(27) mpx(17) mpx(17) mpx(17)
  &:hover
    .swiper-navigation-button
      opacity: 1
  a
    width: 100%
    height: 100%
  &-icon
    position: absolute
    right: dpx(12)
    top: dpx(12)
    width: dpx(44)
    height: dpx(44)
    object-fit: contain
    pointer-events: none
    @media #{$tablet}
      right: tpx(10)
      top: tpx(10)
      width: tpx(38)
      height: tpx(38)
    @media #{$mobile}
      right: mpx(7)
      top: mpx(7)
      width: mpx(26)
      height: mpx(26)
  &-preview
    width: 100%
    @include catalogSwiper
  &-name
    font-size: dpx(16)
    line-height: dpx(19)
    font-weight: 700
    margin-bottom: dpx(3)
    margin-top: auto
    @media #{$tablet}
      font-size: tpx(14)
      line-height: tpx(16)
      margin-bottom: tpx(2)
    @media #{$mobile}
      font-size: mpx(10)
      line-height: mpx(12)
      margin-bottom: mpx(1)
      margin-top: mpx(28)
  &-model
    font-size: dpx(14)
    line-height: dpx(16)
    margin-bottom: dpx(24)
    @media #{$tablet}
      font-size: tpx(14)
      line-height: tpx(16)
    @media #{$mobile}
      font-size: mpx(10)
      line-height: mpx(12)
      margin-bottom: mpx(10)
