.animation-modal
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  display: flex
  align-items: center
  justify-content: center
  background-color: transparentize($white, 0.4)
  z-index: 20
  &-content
    width: dpx(1274)
    max-width: calc(100% - dpx(84))
    aspect-ratio: 1274/714
    border-radius: dpx(6)
    background-color: #F8F8F8
    box-shadow: 0 dpx(5) dpx(5) rgba(0, 0, 0, 0.07)
    overflow: hidden
    position: relative
    @media #{$tablet}
      width: tpx(977)
      max-width: calc(100% - tpx(48))
      border-radius: tpx(5)
      box-shadow: 0px tpx(4) tpx(4) rgba(0, 0, 0, 0.07)
    @media #{$mobile}
      width: calc(100% - mpx(34))
      max-width: unset
      aspect-ratio: 1274/800
      border-radius: mpx(5)
      box-shadow: 0px mpx(4) mpx(4) rgba(0, 0, 0, 0.07)
    video
      width: 100%
      aspect-ratio: 1274/610
      display: block
  &-close
    top: dpx(14)
    right: dpx(12)
    position: absolute
    background-color: transparent
    cursor: pointer
    border: none
    z-index: 1
    @media #{$tablet}
      top: tpx(11)
      right: tpx(9)
    @media #{$mobile}
      top: mpx(8)
      right: mpx(7)
    img
      display: block
      width: dpx(31)
      height: dpx(31)
      filter: invert(79%) sepia(13%) saturate(11%) hue-rotate(21deg) brightness(83%) contrast(88%)
      @media #{$tablet}
        width: tpx(24)
        height: tpx(24)
      @media #{$mobile}
        width: mpx(18)
        height: mpx(18)
  &-controls
    position: absolute
    left: dpx(36)
    bottom: dpx(32)
    display: flex
    align-items: center
    justify-content: flex-start
    @media #{$tablet}
      left: tpx(28)
      bottom: tpx(25)
    @media #{$mobile}
      left: mpx(17)
      bottom: mpx(17)
      padding-right: mpx(17)
    &-button
      border: none
      background: transparent
      cursor: pointer
      margin-right: dpx(19)
      @media #{$tablet}
        margin-right: tpx(15)
      @media #{$mobile}
        margin-right: mpx(10)
      img
        display: block
        width: dpx(61)
        height: dpx(61)
        @media #{$tablet}
          width: tpx(47)
          height: tpx(47)
        @media #{$mobile}
          width: mpx(30)
          height: mpx(30)
    span
      font-size: dpx(21)
      line-height: dpx(24)
      font-weight: 400
      @media #{$tablet}
        font-size: tpx(16)
        line-height: tpx(19)
      @media #{$mobile}
        font-size: mpx(12)
        line-height: mpx(14)
