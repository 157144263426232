.branches-list
  width: 100%
  height: 100%
  padding: dpx(68) dpx(30) 0 dpx(30)
  position: relative
  @media #{$tablet}
    padding: tpx(68) tpx(46) 0 tpx(46)
  @media #{$mobile}
    padding: mpx(16) mpx(17) 0 mpx(17)
  &-overflow
    width: 100%
    height: 100%
    overflow: auto
    padding-bottom: dpx(100)
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    gap: dpx(100)
    @media #{$tablet}
      padding-bottom: tpx(100)
      gap: tpx(100)
    @media #{$mobile}
      padding-bottom: mpx(50)
      gap: mpx(40)
    &::-webkit-scrollbar
      @include scrollbar
      background: transparent
    &::-webkit-scrollbar-thumb
      @include scrollbarThumb
    &::-webkit-scrollbar-track
      margin: 0 0 dpx(46) 0
      @include scrollbar
      @media #{$tablet}
        margin: 0 0 tpx(46) 0
      @media #{$mobile}
        margin: 0 0 mpx(18) 0
    &::-webkit-scrollbar-button
      @include scrollbarButton
  &-item
    width: 100%
    h1
      font-size: dpx(28)
      line-height: dpx(33)
      color: $color-03
      @media #{$tablet}
        font-size: tpx(24)
        line-height: tpx(28)
      @media #{$mobile}
        font-size: mpx(21)
        line-height: mpx(25)
    button
      display: none
      align-items: center
      justify-content: flex-start
      margin-top: dpx(15)
      border: none
      background: transparent
      font-size: dpx(16)
      line-height: dpx(19)
      text-decoration: underline
      cursor: pointer
      @media #{$tablet}
        margin-top: tpx(14)
        font-size: tpx(16)
        line-height: tpx(19)
      @media #{$mobile}
        margin-top: mpx(14)
        font-size: mpx(16)
        line-height: mpx(19)
      &.visible-desktop
        display: flex
        @media #{$tablet}
          display: none
        @media #{$mobile}
          display: none
      &.visible-tablet
        @media #{$tablet}
          display: flex
        @media #{$mobile}
          display: none
      &.visible-mobile
        @media #{$mobile}
          display: flex
      img
        @include noSelect
        transform: rotate(90deg)
        margin-left: dpx(13)
        display: block
        width: dpx(8)
        @media #{$tablet}
          margin-left: tpx(13)
          width: tpx(8)
        @media #{$mobile}
          margin-left: mpx(13)
          width: mpx(8)
      .active-show
        display: none
      &.active
        img
          transform: rotate(-90deg)
        .active-show
          display: unset
        .active-hide
          display: none
    &-row
      display: grid
      grid-template-columns: repeat(4, 1fr)
      gap: dpx(61) 0
      margin-top: dpx(40)
      @media #{$tablet}
        grid-template-columns: repeat(2, 1fr)
        gap: tpx(60) 0
        margin-top: tpx(40)
      @media #{$mobile}
        grid-template-columns: repeat(1, 1fr)
        gap: mpx(30) 0
        margin-top: mpx(20)
      .branches-list-contact
        display: none
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4)
          display: flex
        &:nth-child(2)
          @media #{$mobile}
            display: none
        &:nth-child(3),
        &:nth-child(4)
          @media #{$tablet}
            display: none
      &--active
        .branches-list-contact
          display: flex !important
  &-contact
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    &-name
      font-size: dpx(22)
      line-height: dpx(26)
      margin-bottom: dpx(6)
      @media #{$tablet}
        font-size: tpx(21)
        line-height: tpx(25)
        margin-bottom: tpx(6)
      @media #{$mobile}
        font-size: mpx(18)
        line-height: mpx(21)
        margin-bottom: mpx(6)
    &-description
      font-size: dpx(18)
      line-height: dpx(21)
      margin-bottom: dpx(26)
      font-weight: 400
      color: #494949
      @media #{$tablet}
        font-size: tpx(16)
        line-height: tpx(19)
        margin-bottom: tpx(26)
      @media #{$mobile}
        font-size: mpx(12)
        line-height: mpx(14)
        margin-bottom: mpx(14)
    &-block
      display: flex
      align-items: flex-start
      justify-content: flex-start
      width: 100%
      margin-top: dpx(10)
      @media #{$tablet}
        margin-top: tpx(10)
      @media #{$mobile}
        margin-top: mpx(6)
      &:nth-child(3)
        margin-top: 0 !important
      &-values
        width: 100%
        display: flex
        flex-direction: column
        align-items: flex-start
        justify-content: flex-start
        gap: dpx(5)
        @media #{$tablet}
          gap: tpx(5)
        @media #{$mobile}
          gap: mpx(5)
      img
        @include noSelect
        display: block
        width: dpx(27)
        margin-right: dpx(14)
        @media #{$tablet}
          width: tpx(27)
          margin-right: tpx(14)
        @media #{$mobile}
          width: mpx(20)
          margin-right: mpx(14)
      span, a
        font-weight: 500
        font-size: dpx(20)
        line-height: dpx(23)
        @media #{$tablet}
          font-size: tpx(18)
          line-height: tpx(21)
        @media #{$mobile}
          font-size: mpx(14)
          line-height: 140%
      span
        display: block

