.catalog-categories
  position: relative
  width: calc(100% - dpx(20))
  height: dpx(80)
  border-radius: dpx(49)
  background: #E2E2E2
  display: flex
  align-items: flex-start
  justify-content: flex-start
  padding: dpx(18) dpx(18) dpx(17) dpx(28)
  margin-bottom: dpx(16)
  z-index: 2
  @media #{$tablet}
    width: calc(100% - tpx(18))
    height: tpx(80)
    border-radius: tpx(49)
    padding: tpx(18) tpx(15) tpx(17) tpx(34)
    margin-bottom: tpx(32)
  @media #{$mobile}
    width: 100%
    height: mpx(62)
    border-radius: 0
    background: transparent
    padding: 0
    transform: none
    margin-bottom: mpx(18)
    flex-direction: column
  &--toggleable,
  &--active
    .catalog-categories-toggle
      display: flex
  &--toggleable
    @media #{$mobile}
      height: mpx(97)
  &--active
    height: auto !important
    .catalog-categories-toggle
      img
        transform: rotate(90deg)
  &-list
    display: flex
    flex-wrap: wrap
    align-items: flex-start
    justify-content: flex-start
    gap: dpx(13)
    width: 100%
    height: auto
    @media #{$tablet}
      gap: tpx(13)
    @media #{$mobile}
      gap: mpx(13)
    &-wrapper
      width: 100%
      height: 100%
      overflow: hidden
      padding: dpx(7) 0
      @media #{$tablet}
        padding: tpx(7) 0
      @media #{$mobile}
        padding: 0
  &-item
    display: flex
    align-items: center
    justify-content: center
    border-radius: dpx(60)
    border: 2px solid $color-01
    background-color: $color-05
    cursor: pointer
    padding: dpx(4) dpx(16)
    white-space: nowrap
    font-size: dpx(16)
    line-height: dpx(19)
    text-align: center
    width: auto !important
    position: relative
    @media #{$tablet}
      border-radius: tpx(60)
      padding: tpx(5) tpx(14)
      font-size: tpx(14)
      line-height: tpx(16)
    @media #{$mobile}
      border-radius: mpx(50)
      padding: mpx(3) mpx(15)
      font-size: mpx(12)
      line-height: mpx(14)
    img
      position: absolute
      top: dpx(5)
      right: dpx(7)
      width: dpx(18)
      height: dpx(18)
      pointer-events: none
      display: none
      @media #{$tablet}
        top: tpx(6)
        right: tpx(7)
        width: tpx(15)
        height: tpx(15)
      @media #{$mobile}
        top: mpx(4)
        right: mpx(7)
        width: mpx(15)
        height: mpx(15)
    &:nth-last-child(1)
      margin-right: 0
    &--active
      background-color: $color-04 !important
      border-color: $color-04 !important
      padding-right: dpx(35)
      @media #{$tablet}
        padding-right: tpx(30)
      @media #{$mobile}
        padding-right: mpx(30)
      img
        display: block
  &-buttons
    display: flex
    align-items: center
    justify-content: flex-end
    flex: none
    margin-left: dpx(36)
    min-width: dpx(255)
    @media #{$tablet}
      margin-left: tpx(36)
      min-width: tpx(255)
    @media #{$mobile}
      margin-left: auto
      min-width: unset
  &-home
    flex: none
    margin-left: dpx(12)
    @media #{$tablet}
      margin-left: tpx(12)
    @media #{$mobile}
      display: none
    img
      display: block
      width: dpx(45)
      height: dpx(45)
      @media #{$tablet}
        width: tpx(45)
        height: tpx(45)
  .searchbar
    margin-left: dpx(28)
    @media #{$tablet}
      margin-left: tpx(28)
    @media #{$mobile}
      display: none
    button
      left: dpx(10)
      @media #{$tablet}
        left: tpx(11)
    input
      width: dpx(45)
      height: dpx(45)
      padding: 0
      cursor: pointer
      color: transparentize($color-02, 1)
      @media #{$tablet}
        width: tpx(45)
        height: tpx(45)
      &::placeholder
        color: transparentize($color-02, 1)
    &--active
      button
        left: dpx(341)
        @media #{$tablet}
          left: tpx(197)
        @media #{$mobile}
          left: mpx(108)
      input
        padding: 0 dpx(40) 0 dpx(20)
        width: dpx(376)
        color: $color-02
        @media #{$tablet}
          padding: 0 tpx(40) 0 tpx(20)
          width: tpx(231)
        @media #{$mobile}
          padding: 0 mpx(20) 0 mpx(10)
          width: mpx(126)
        &::placeholder
          color: $color-06
  &-toggle
    flex: none
    background: transparent
    border: none
    display: none
    align-items: center
    justify-content: flex-end
    cursor: pointer
    font-size: dpx(14)
    line-height: dpx(17)
    text-decoration: underline
    width: dpx(123)
    white-space: nowrap
    @media #{$tablet}
      font-size: tpx(14)
      line-height: tpx(17)
      width: tpx(125)
    @media #{$mobile}
      position: relative
      margin-left: auto
      margin-top: mpx(10)
      font-size: mpx(14)
      line-height: mpx(17)
      width: unset
    img
      display: block
      width: dpx(24)
      height: dpx(24)
      object-fit: contain
      transform: rotate(-90deg)
      margin-left: dpx(6)
      @media #{$tablet}
        width: tpx(24)
        height: tpx(24)
        margin-left: tpx(6)
      @media #{$mobile}
        width: mpx(24)
        height: mpx(24)
        margin-left: mpx(6)
