.close-group
  position: absolute
  right: dpx(42)
  top: dpx(33)
  display: grid
  grid-template-columns: repeat(2, auto)
  gap: dpx(28)
  background-color: rgba(218, 218, 218, 0.36)
  border: dpx(1) solid $color-01
  border-radius: dpx(49)
  padding: dpx(18)
  backdrop-filter: blur(4.5px)
  z-index: 5
  @media #{$tablet}
    border-width: tpx(1)
    right: tpx(24)
    top: tpx(35)
    border-radius: tpx(49)
    padding: tpx(17)
    gap: 0 tpx(28)
  @media #{$mobile}
    border-width: mpx(1)
    right: mpx(16)
    top: mpx(18)
    border-radius: 0
    padding: 0
    gap: 0 mpx(9)
    background: transparent
    border: none
  &:before
    content: ''
    position: absolute
    width: dpx(1)
    height: dpx(23)
    background-color: $color-05
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    @media #{$tablet}
      width: tpx(1)
      height: tpx(23)
    @media #{$mobile}
      display: none
  button, a
    border: none
    background-color: $color-05
    border: dpx(1) solid $color-05
    cursor: pointer
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    width: dpx(45)
    height: dpx(45)
    @media #{$tablet}
      border-width: tpx(1)
      width: tpx(45)
      height: tpx(45)
    @media #{$mobile}
      width: mpx(34)
      height: mpx(34)
      border: mpx(1) solid rgba(166, 166, 166, 0.29)
      background-color: transparent
    img
      width: dpx(24)
      height: dpx(24)
      display: block
      object-fit: contain
      @include noSelect
      @media #{$tablet}
        width: tpx(24)
        height: tpx(24)
      @media #{$mobile}
        width: mpx(18)
        height: mpx(18)
        filter: invert(41%) sepia(3%) saturate(10%) hue-rotate(357deg) brightness(94%) contrast(93%)
  &-button
    &--back
      img
        margin-left: dpx(-1)
        @media #{$tablet}
          margin-left: tpx(-1)
