.login
  &-page
    width: 100%
    height: 100%
    position: relative
    padding: dpx(33) dpx(42)
    max-width: dpx(1920)
    margin: 0 auto
    display: flex
  &-form
    width: 100%
    max-width: 500px
    margin: auto
    display: flex
    flex-direction: column
    align-items: center
    justify-content: flex-start
    gap: 20px
    padding: 50px 30px 60px 30px
    background-color: $white
    border-radius: 15px
    h2
      margin-bottom: 10px
    input,
    button
      width: 100%
      max-width: 300px
      height: 43px
      padding: 0 20px
      border-radius: 101px
      border: none
      background-color: #E8E8E8
    button
      background-color: $color-04
      cursor: pointer
