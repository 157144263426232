.about-page
  position: relative
  width: 100%
  height: 100%
  padding: dpx(69) dpx(24) dpx(95) dpx(48)
  background-color: $color-05
  border-radius: dpx(21)
  @media #{$tablet}
    padding: tpx(36) tpx(23) tpx(58) tpx(46)
    border-radius: tpx(21)
  @media #{$mobile}
    padding: mpx(19) mpx(8) 0 mpx(16)
    overflow: hidden auto
  h1
    font-weight: 700
    font-size: dpx(36)
    line-height: dpx(42)
    margin-bottom: dpx(36)
    color: $color-03
    @media #{$tablet}
      font-size: tpx(32)
      line-height: tpx(38)
      margin-bottom: tpx(26)
    @media #{$mobile}
      font-size: mpx(21)
      line-height: mpx(25)
      margin-bottom: mpx(20)
      max-width: mpx(235)
  &-row
    display: flex
    align-items: flex-start
    justify-content: flex-start
    height: calc(100% - dpx(78))
    width: 100%
    @media #{$tablet}
      height: calc(100% - tpx(64))
      flex-direction: column
    @media #{$mobile}
      height: auto
  &-video
    width: calc(100% - dpx(771))
    height: 100%
    margin-right: dpx(60)
    background-color: $color-08
    border-radius: dpx(10)
    min-width: dpx(500)
    @media #{$tablet}
      aspect-ratio: 886/500
      width: calc(100% - tpx(23))
      height: auto
      margin-right: 0
      border-radius: tpx(10)
      flex: none
      margin-bottom: tpx(26)
      min-width: unset
    @media #{$mobile}
      aspect-ratio: 309/172
      width: calc(100% - mpx(8))
      border-radius: mpx(5)
      margin-bottom: mpx(20)
    iframe
      width: 100%
      height: 100%
      display: block
      border-radius: inherit
      border: none
  &-text
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    height: 100%
    overflow: hidden auto
    gap: dpx(24)
    padding-right: dpx(24)
    width: dpx(711)
    @media #{$tablet}
      gap: tpx(34)
      padding-right: tpx(23)
      width: 100%
      height: auto
    @media #{$mobile}
      gap: mpx(24)
      padding-right: mpx(12)
      padding-bottom: mpx(16)
    &::-webkit-scrollbar
      @include scrollbar
      @media #{$mobile}
        background: transparent
    &::-webkit-scrollbar-thumb
      @include scrollbarThumb
    &::-webkit-scrollbar-track
      @include scrollbarTrack
      @media #{$mobile}
        display: block
        margin: 0 0 mpx(16) 0
        @include scrollbar
    &::-webkit-scrollbar-button
      @include scrollbarButton
    p
      font-weight: 500
      font-size: dpx(18)
      line-height: dpx(23)
      @media #{$tablet}
        font-size: tpx(18)
        line-height: tpx(23)
      @media #{$mobile}
        font-size: mpx(14)
        line-height: mpx(16)
    &-numbers
      order: 1
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%
      margin: dpx(36) 0
      gap: dpx(30)
      flex-wrap: wrap
      @media #{$tablet}
        margin: tpx(16) 0
        justify-content: space-around
      @media #{$mobile}
        margin: 0 0 mpx(6) 0
        justify-content: space-between
        order: 0
    &-number
      display: flex
      align-items: center
      justify-content: flex-start
      @media #{$mobile}
        flex-direction: column
        align-items: flex-start
      b
        font-weight: 500
        font-size: dpx(64)
        line-height: dpx(64)
        margin-right: dpx(6)
        color: $color-03
        @media #{$tablet}
          font-size: tpx(64)
          line-height: tpx(64)
          margin-right: tpx(6)
        @media #{$mobile}
          font-size: mpx(32)
          line-height: mpx(32)
          margin-right: 0
          margin-bottom: mpx(8)
      span
        font-weight: 500
        font-size: dpx(16)
        line-height: dpx(19)
        max-width: dpx(116)
        white-space: pre-line
        @media #{$tablet}
          font-size: tpx(16)
          line-height: tpx(19)
          max-width: tpx(116)
        @media #{$mobile}
          font-size: mpx(12)
          line-height: mpx(13)
          max-width: mpx(82)
